import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Footer from '../../components/footer';
import { Map, Marker } from 'react-amap';
import './index.css';
import contact_01 from '../../images/contact/contact_01.jpg';
import contact_02 from '../../images/contact/contact_02.jpg';
import contact_03 from '../../images/contact/contact_03.jpg';
import contact_04 from '../../images/contact/contact_04.jpg';
import contact_05 from '../../images/contact/contact_05.jpg';
import contact_06 from '../../images/contact/contact_06.jpg';
import contact_07 from '../../images/contact/contact_07.jpg';
import contact_08 from '../../images/contact/contact_08.jpg';
import contact_tel from '../../images/contact/contact_tel.png';
import contact_addr from '../../images/contact/contact_addr.png';
import contact_email from '../../images/contact/contact_email.png';

const Contact = () => {
  let position = { longitude: 106.4998626771569, latitude: 29.60882272118883 };

  return (
    <div className="section">
      <div className="contact-banner">
        <Swiper autoplay={{ delay: 4000 }} loop={true} speed={1000} pagination={{ clickable: true }}>
          <SwiperSlide>
            <div className="contact-banner-item" style={{ backgroundImage: `url(${contact_01})` }}>
              <div className="container">
                <div className="contact-banner-content">
                  <div className="m-box">
                    <div className="m-text">
                      <p>森梅医疗科技是一家专注于【慢性病临床病情管理】解决方案的科技公司</p>
                      <p>
                        <br />
                      </p>
                      <p>公司以改善患者生存质量为使命，以尊 重科学、尊重伦理为价值观</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contact-banner-item" style={{ backgroundImage: `url(${contact_02})` }}>
              <div className="container">
                <div className="contact-banner-content">
                  <div className="m-box">
                    <div className="m-text">
                      <p>森梅医疗科技是一家专注于【慢性病临床病情管理】解决方案的科技公司</p>
                      <p>
                        <br />
                      </p>
                      <p>公司以改善患者生存质量为使命，以尊 重科学、尊重伦理为价值观</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contact-banner-item" style={{ backgroundImage: `url(${contact_03})` }}>
              <div className="container">
                <div className="contact-banner-content">
                  <div className="m-box">
                    <div className="m-text">
                      <p>森梅医疗科技是一家专注于【慢性病临床病情管理】解决方案的科技公司</p>
                      <p>
                        <br />
                      </p>
                      <p>公司以改善患者生存质量为使命，以尊 重科学、尊重伦理为价值观</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contact-banner-item" style={{ backgroundImage: `url(${contact_04})` }}>
              <div className="container">
                <div className="contact-banner-content">
                  <div className="m-box">
                    <div className="m-text">
                      <p>森梅医疗科技是一家专注于【慢性病临床病情管理】解决方案的科技公司</p>
                      <p>
                        <br />
                      </p>
                      <p>公司以改善患者生存质量为使命，以尊 重科学、尊重伦理为价值观</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contact-banner-item" style={{ backgroundImage: `url(${contact_05})` }}>
              <div className="container">
                <div className="contact-banner-content">
                  <div className="m-box">
                    <div className="m-text">
                      <p>森梅医疗科技是一家专注于【慢性病临床病情管理】解决方案的科技公司</p>
                      <p>
                        <br />
                      </p>
                      <p>公司以改善患者生存质量为使命，以尊 重科学、尊重伦理为价值观</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="contact-us">
        <div className="container">
          <div className="contact-title">
            <h3>联系我们</h3>
            <h4>contact us</h4>
          </div>
          <div className="contact-top">
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <div className="contact-us-item">
                  <div className="m-img">
                    <img src={contact_06} />
                  </div>
                  <div className="m-cont">
                    <div className="m-tit">医院合作</div>
                    <div className="m-des">mark@thinmedtech.com</div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="contact-us-item">
                  <div className="m-img">
                    <img src={contact_07} />
                  </div>
                  <div className="m-cont">
                    <div className="m-tit">商务拓展</div>
                    <div className="m-des">mark@thinmedtech.com</div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="contact-us-item">
                  <div className="m-img">
                    <img src={contact_08} />
                  </div>
                  <div className="m-cont">
                    <div className="m-tit">人才招聘</div>
                    <div className="m-des">sj.lee@139.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-bottom">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="contact-map">
                  <Map zoom={18} center={position}>
                    <Marker position={position} />
                  </Map>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="constact-info">
                  <ul>
                    <li className="clearfix">
                      <div className="m-left">
                        <img src={contact_tel} />
                      </div>
                      <div className="m-right">
                        <div className="m-tit">电话</div>
                        <div className="m-des">
                          <a href="tel:023-86849137">023-86849137</a>
                        </div>
                      </div>
                    </li>
                    <li className="clearfix">
                      <div className="m-left">
                        <img src={contact_email} />
                      </div>
                      <div className="m-right">
                        <div className="m-tit">邮箱</div>
                        <div className="m-des">
                          <a href="mailto:mark@thinmedtech.com">mark@thinmedtech.com</a>
                        </div>
                      </div>
                    </li>
                    <li className="clearfix">
                      <div className="m-left">
                        <img src={contact_addr} />
                      </div>
                      <div className="m-right">
                        <div className="m-tit">地址</div>
                        <div className="m-des">重庆市渝北区洪湖西路26号北部新区软件园</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
