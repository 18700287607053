import axios from 'axios';

/**
 * Android 下载 APK
 * @param {*} type 1:患者App 2:医生app 4:华西患者App 5店员App
 */
export function downloadApk(type) {
  var conf = {
    1: {
      baseURL: 'https://api-v3-patient.sersmed.com',
      url: '/patient/app-version/index',
    },
    2: {
      baseURL: 'https://api-v3-doctor.sersmed.com',
      url: '/doctor-app/app-version/index',
    },
    4: {
      baseURL: 'https://api-v3-patient.sersmed.com',
      url: '/patient/app-version/index',
    },
    5: {
      baseURL: 'https://api-v3-pharmacy.sersmed.com',
      url: '/clerk/app/version/check',
    },
  };

  var req = axios.create({
    baseURL: conf[type].baseURL,
    timeout: 30000,
    responseType: 'json',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json, text/javascript',
      'Cache-Control': 'no-cache',
    },
    mode: 'cors',
  });

  let sendData = 'version_code=1';
  if (type == 1 || type == 4) {
    sendData += '&app=' + type;
  }

  req
    .request({
      url: conf[type].url,
      method: 'post',
      data: sendData,
    })
    .then((res) => {
      if (res.data.data && res.data.data.down_url) {
        window.location.href = res.data.data.down_url;
        return false;
      }
      alert('下载出现异常：' + res.data.error);
    });
}

/* 应用链接 */
function appLink() {
  var search = window.location.search.substring(1);
  var searchArr = decodeURIComponent(search).split('&');
  var searchObj = {};
  for (var i = 0; i < searchArr.length; i++) {
    searchObj[searchArr[i].split('=')[0]] = searchArr[i].split('=')[1];
  }
  if (searchObj.type === 'link') {
    var openBtn = document.getElementById('openBtn');
    var btns = document.getElementById('btns');
    var scheme = {
      patienthuaxi: 'sersmed-patienthuaxi://',
      patient: 'sersmed-patient://',
      doctor: 'sersmed-doctor://',
      pharmacy: 'sersmed-pharmacy://',
    };

    /* 设置打开App地址 */
    if (searchObj.app && scheme[searchObj.app]) {
      openBtn.setAttribute('href', scheme[searchObj.app] + searchObj.path);
    }

    /* 判断是ios还是Android */
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    if (isIOS) {
      btns.setAttribute('class', 'ios');
    } else {
      btns.setAttribute('class', 'android');
    }
  }
  /* 从官网进入  需要显示页面切换按钮 */
  if (searchObj.official != undefined) {
    var openBtn = document.getElementById('select-content');
    // openBtn.style.display = 'block'; //web端的官网已经提出去单独写的  与此项目无关
  }
}

window.onload = function () {
  appLink();
};
