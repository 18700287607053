import React from 'react';
import Footer from '../../components/footer';
import swc from '../../images/news/swc.jpg';
import ldt from '../../images/news/ldt.png';
import whm from '../../images/news/whm.png';
import xjc from '../../images/news/xjc.jpg';
import lxb from '../../images/news/lxb.jpg';
import zj from '../../images/news/zj.jpg';
import lhd from '../../images/news/lhd.png';
import cx from '../../images/news/cx.jpg';
import drvs from '../../images/news/drvs.jpg';
import yz from '../../images/news/yz.png';

const Detailsfour = (props) => {
  return (
    <div>
      <div className="totaldetailstyle">
        <div className={'detailtop'} style={{ height: '100px', width: '100vw' }}></div>
        <div className="container">
          <div className="detailstitle">数字疗法产业中国行(重庆站）：探索数字疗法的“中国实践”</div>
          <div className="fromtime">
            <div className="titletime">来源媒体：动脉网</div>
            <div className="titlefrom">发布时间：2021-06-01</div>
          </div>
          <div className="detailtext first-detals-text">
            2021年5月28日，中国第一个数字疗法正式组织——中国康复技术转化及发展促进会数字疗法系统工程专业委员会（筹）（以下简称（专委会（筹）），在重庆开展数字疗法产业中国行活动，汇集数字疗法核心力量，共同探讨产业未来实践。
            本次活动由中国康复技术转化及发展促进会数字疗法系统工程专业委员会（筹）主办；动脉网、远毅资本、武田（中国）、晖致、微脉、复星医药联合主办；天士力、森梅医疗、华诺智胜、妙健康协办；咏柳科技、云开亚美、中欧科技创新合作、欧盟研究与创新杭州中心、重庆渝中大健康产业发展有限公司支持。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            森梅医疗考察行:专注提供慢性病临床病情管理解决方案
          </div>
          <div className="detailtext">
            上午9点半，多位数字疗法创新企业代表人齐聚森梅医疗科技有限公司，开启考察活动。森梅医疗作为重庆数字疗法标杆企业，创始人张敬为大家介绍了肃医™APP的实践经验，各位嘉宾也探讨了各自在数字疗法领域的探索，并在自由问答环节展开积极讨论。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            数字疗法产业中国行重庆城市峰会
          </div>
          {/* <div className="detial-img-box">
          <img src={bottom3} className="detals-img" />
        </div> */}
          <div className="detailtext">
            下午14点，数字疗法产业中国行重庆城市峰会上，中国康复技术转化及发展促进会候任副会长兼执行秘书长宋无尘、动脉网创始人李大韬分别发表致辞，特邀临床专家重庆市人民医院内分泌科主任医师
            王红漫、陆军军医大学第二附属医院肾内科主治医师
            熊加川；优秀企业家代表华诺智胜创始人CEO刘泓豆、森梅医疗创始人张敬、妙健康高级副总裁兼首席医疗官罗晓斌，以及术康首席医生兼研发部医学主管、华西临床医学博士陈曦为大家带来精彩分享。同时，有幸邀请到海外嘉宾Orange
            Neurosciences 总裁兼首席执行官Dr. Vinay Singh现场连线。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            宋无尘 致辞
          </div>
          <div className="detial-img-box">
            <img src={swc} className="detals-img" />
            <div style={{ marginTop: '10px' }}>中国康复技术转化及发展促进会候任副会长兼执行秘书长 宋无尘</div>
          </div>
          <div className="detailtext">
            作为国家级的行业协会，中国康复技术转化及发展促进会积极响应健康中国战略的号召，顺应数字化医疗的时代浪潮，筹备设立数字疗法系统工程专业委员会，和动脉网以及远毅资本一起来推动中国数字疗法产业的发展。
          </div>
          <div className="detailtext">
            我们希望通过专业委员会的设立，整合行业内的专家、学者以及龙头企业，制定技术标准、临床路径，推进医疗健康领域的技术创新以及临床应用，规范市场、促进行业自律。
          </div>
          <div className="detailtext">
            同时，我们也期待未来能够为整个国家的产业发展贡献自己的力量，孵化更多优质企业，形成产业集群，通过与地方政府合作系统化的打造一个数字疗法产学研一体化发展的平台。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            李大韬 致辞
          </div>
          <div className="detial-img-box">
            <img src={ldt} className="detals-img" />
            <div style={{ marginTop: '10px' }}>动脉网创始人 李大韬</div>
          </div>
          <div className="detailtext">
            任何一个时代所遇到的核心挑战，最后的解决都是通过新的路径解决的，我觉得数字疗法或者医疗产业的数字化，某种程度上也类似于这样一个大的变革。
          </div>
          <div className="detailtext">
            希望未来我们能够通过数字疗法系统工程专业委员会，通过协会，去推动在数字和医疗结合过程中专家共识的达成，以及我们创新案例的快速延展，能够找到更好实践的主航道，最后通过我们的实践反向去影响我们国家的一些监管政策。
          </div>
          <div className="detailtext">
            各位嘉宾围绕着国内数字疗法的落地情况如何，临床实验发展如何，市场前景如何，如何构建商业路径等问题展开演讲，动脉网对嘉宾的精彩观点进行了整理。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            王红漫：医生视角下的数字疗法
          </div>
          <div className="detial-img-box">
            <img src={whm} className="detals-img" />
            <div style={{ marginTop: '10px' }}>重庆市人民医院内分泌科主任医师 王红漫</div>
          </div>
          <div className="detailtext">慢病管理的鸿沟：</div>

          <div className="detailtext">
            慢病后面所蕴藏的是这个人的行为模式与它相关的很多数据的变化，但是医疗是无法覆盖患者所有行为模式确认的需求，造成了慢病管理很难。医生只占不到10%的决定权，跟患者相关是占了绝对因素，导致了这个鸿沟是现在医疗模式无法去改变的。
          </div>
          <div className="detailtext">展望未来问题：</div>
          <div className="detailtext">1、缺乏更多的数字技术</div>
          <div className="detailtext">
            2、治疗一个疾病是改变一个人，所以它更多的数据采集需要我们临床上摸索，同时还要有具有教练能力的管理团队、成本、操作性、评价性。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            熊加川：数字医疗真实世界数据和证据的临床试验验证
          </div>
          <div className="detial-img-box">
            <img src={xjc} className="detals-img" />
            <div style={{ marginTop: '10px' }}>陆军军医大学第二附属医院肾内科主治医师 熊加川</div>
          </div>
          <div className="detailtext">作为一个医生来讲，一个信息化慢病管理平台，需要哪些特征？</div>
          <div className="detailtext">1、全信息化的数据管理和随访</div>
          <div className="detailtext">2、数据双向传输（管理端和患者端）+APP端</div>
          <div className="detailtext">3、提供患教、知识推送和问卷调查等</div>
          <div className="detailtext">4、远程智能监测和预警</div>
          <div className="detailtext">5、扩展端：科研统计分析，在线统计，RCT研究入组</div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            罗晓斌：数字疗法切入保险的路径探索
          </div>
          <div className="detial-img-box">
            <img src={lxb} className="detals-img" />
            <div style={{ marginTop: '10px' }}>妙健康高级副总裁兼首席医疗官 罗晓斌</div>
          </div>
          <div className="detailtext">
            第一个就是如何将这种数字化健康管理能力或者说我们未来有可能形成的数字疗法去嵌入整个保险的作业流程，去嵌入整个保险的应用场景。
          </div>

          <div className="detailtext">
            第二个做了这些数据以后，实际上更加重要的是怎么量化现在的健康值。有了数据，有了评估，也就是所谓的健康分级，那么下一步就是干预、遇到的最大的问题，第一我们缺乏足够多数量的专业人员，第二需要有效率工具。
          </div>
          <div className="detailtext">
            如果这个行业要更加多的健康发展，一方面我们是需要真实，通过真实世界的研究去沉淀对行业发展有意义的数据，要积累这些真正有意义的案例。另外一个就是要推动整个行业的从政策制定到支付方式模式创新的整体探索。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            张敬：肃医临床级数字疗法（DTX）平台
          </div>
          <div className="detial-img-box">
            <img src={zj} className="detals-img" />
            <div style={{ marginTop: '10px' }}>森梅医疗创始人 张敬</div>
          </div>
          <div className="detailtext">
            数字疗法的问题，它很难让临床在这个价值认知上改变。怎么让他们愿意花时间来使用我们的产品服务患者，我们要反过来思考，可能得先解决临床问题。临床问题无非就一个是我们的效率，一个是我们的经营，还有一个就是我们的研究。其实它单点的需求痛点很难形成一个持续使用的闭环。
          </div>
          <div className="detailtext">
            从医院影像、电子病历，数据治理到辅助决策，森梅做的就是指南，目前是以专科（肾脏科）为单位，从全国的研发型医院着手，选取该领域中参与指南撰写的医院，参与重塑高于指南的诊断标准，让医生真正愿意用。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            刘泓豆：运用数字疗法重新定义糖尿病管理
          </div>
          <div className="detial-img-box">
            <img src={lhd} className="detals-img" />
            <div style={{ marginTop: '10px' }}>华诺智胜创始人、CEO 刘泓豆</div>
          </div>
          <div className="detailtext">
            华诺智胜专注于糖尿病及代谢性疾病管理的数字疗法，通过四年的扎实研发积淀，打造了国内首个糖尿病多学科干预数字疗法产品——睿控糖，并率先完成了商业化验证，累计付费患者过千名。
          </div>
          <div className="detailtext">
            四年时间对单病种的数字疗法探索，分享如何构建一个数字疗法的商业模式，第一步是要深入理解某个疾病的治疗负担及治疗现状。第二个是根据临床方案和技术应用两大支柱建立数字疗法。第三是通过单病种价值链评估等建立研发和运营策略。
          </div>
          <div className="detailtext">
            做数字疗法，从研发到疗法运营的八步法也是数字疗法企业要面对的八重天。我非常推荐大家使用精益创业的理念来进行初创阶段的研发和运营，尽早地验证产品的临床效果、适应症和商业模式，根据真实客观反馈，用螺旋方法迭代产品protype和运营模式，可以降低风险，提高成功率。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            陈曦：数字疗法在居家康复的应用
          </div>
          <div className="detial-img-box">
            <img src={cx} className="detals-img" />
            <div style={{ marginTop: '10px' }}>华术康首席医生兼研发部医学主管、华西临床医学博士 陈曦</div>
          </div>
          <div className="detailtext">
            数字疗法虽然很新，但是真的如果作为疗法去使用，作为医疗器械使用必须有完整的循证医学证据链，涉及的文献非常多，有很多高质量的文献证据。
          </div>
          <div className="detailtext">
            通过运动康复达到治疗这些疾病，真的达到安全和有效的疗效，达到这个级别是有非常严格的要求。这个理论体系叫运动测试与运动处方，这个指南更新到第十版，它证据链非常多。同时也很重要，为什么证据这么多在临床上使用又很少，这个就是我们一起面临的问题。
          </div>
          <div className="detailtext">
            数字疗法不应该是成为一个噱头，一个概念，应该真正的深入临床，在临床中真正的发现发现那些有可能传统医疗解决不了的问题。这个时候科技不是为了科技炫酷本身，而是应该解决实际的问题。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            海外连线：Dr.Vinay Singh
          </div>
          <div className="detial-img-box">
            <img src={drvs} className="detals-img" />
            <div style={{ marginTop: '10px' }}>Orange Neurosciences 总裁兼首席执行官Dr. Vinay Singh</div>
          </div>
          <div className="detailtext">
            由中欧科技创新合作 运营副总裁王湘玲女士为大家引荐的Orange
            Neurosciences，是一家新兴的数字医疗技术公司，开发了基于在线软件的认知治疗平台，用于神经疾病、老龄化相关疾病和康复，旨在满足全球神经多样性人群对循证医学、经济实惠和可扩展认知治疗的巨大需求。
          </div>
          <div className="detailtext">
            首个商业产品ReadON.ai，是一种由ML/ai和基于神经可塑性的在线软件支持的下一代数字疗法（DTx），为苦苦挣扎的读者提供干预。Titli.ai
            将于2021年7月推出D2C，针对多动症和自闭症的核心症状。同时，Orange Neurosciences
            数字认知治疗平台也可以为神经疾病患者的康复提供医疗服务。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            大咖圆桌：数字疗法的潜在市场机会及挑战
          </div>
          <div className="detial-img-box">
            <img src={yz} className="detals-img" />
          </div>
          <div className="detailtext">核心问题：</div>

          <div className="detailtext">1、数字疗法产业的商业化路径</div>
          <div className="detailtext">
            整个数字疗法的产业，基于付费模式，一定要基于它的产品形态。比如说数字疗法，从大类来讲，有针对老年人，有针对儿童，有针对慢病的。如果说儿童或者老年人，这部分的付费，有可能是直接患者付费，它的意愿比较强。所以这个产品形态，那我们可以做商业模式的搭建。
          </div>
          <div className="detailtext">
            但是如果说针对慢病，医保的结合在短时间类是不太可能的。从资本的层面，数字疗法是一个新型行业，所以更多的资本一定带着战略的眼光孵化这个产业，不能太急功近利的去要求数字化的一些企业在短期之内就迅速膨胀。
          </div>
          <div className="detailtext">
            我们整体的感觉这个行业它是需要孕育的。它的后面的支付模式是需要前期大量真实数据积累的，只有真正带来数据的积累才可能是我们撬开物价，我们把它写进标准，我们去和医保谈，甚至我们和保险协会谈，把它作为一种服务资源。
          </div>
          <div className="detailtext">2、数字疗法产品需不需要拿证</div>
          <div className="detailtext">
            获批这件事情觉得实际是还是比较重要的，它是一个把我们和消费医疗作为一个严格的区分，从产品类别进行了区分。
          </div>
          <div className="detailtext">
            数字疗法，可能更多的它是以一种医疗软件，去做认证。但是真正定义所谓的医疗软件，是要有明确的这类的产品目录，基于产品目录所有申报和评价标准，这块我们现在出于整个行业发展的一个进程中，现在还是空白的。或者是说没有那么清晰，但是不要紧，借鉴美国的经验，借鉴国外的经验也是一样，国外FDA第一款它的政策法规就是和商业公司制定的整个的认证标准，但是它的制定过程不是出于商业公司的利益考虑的，而是出于真正围绕患者它是不是能解决这个问题，如果解决了问题就应该立法，解决了问题就应该制定监管标准，因为这是一个社会责任。
          </div>

          <div style={{ marginTop: '1rem 0' }}>
            {/* <div className="detail-back">
            <div className="detail-back-botton">
              浏览下一篇
            </div>
          </div> */}
            <div className="detail-next">
              <div
                className="detail-next-botton"
                onClick={() => {
                  props.history.push('/news');
                }}
              >
                返回主页
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <Footer />
      </div>
    </div>
  );
};
export default Detailsfour;
