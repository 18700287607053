import React, { Component } from 'react';
import { act } from 'react-dom/cjs/react-dom-test-utils.production.min';
// import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper, SwiperOptions } from 'swiper';
import Footer from '../../components/footer';
import huaxi from '../../images/cases/huaxi.jpg';
import tangdu from '../../images/cases/tangdu.jpg';
import xinjiang from '../../images/cases/xinjiang.jpg';
import xinqiao from '../../images/cases/xinqiao.jpg';
import dalian from '../../images/cases/dalian.jpg';
import xiangya from '../../images/cases/xiangya.jpg';
import guangxi from '../../images/cases/guangxi.jpg';
import renji from '../../images/cases/renji.jpg';
import henanrenming from '../../images/cases/henanrenming.jpg';
import lanzhou from '../../images/cases/lanzhou.jpg';
import shandong from '../../images/cases/shandong.jpg';
import tongji from '../../images/cases/tongji.jpg';
import guanghua from '../../images/cases/guanghua.jpg';
import nanjingzhongda from '../../images/cases/nanjingzhongda.jpg';

import './index.css';

class Cases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          date: '2018-09',
          name: '陆军军医大学第二附属医院',
          desc: '陆军军医大学第二附属医院始建于1944年，对外称新桥医院。医院设有临床和医技科室45个，国家级重点学科10个，国家中医药管理局重点建设专科1个，全军医学研究所4个，全军医学专科（病）中心6个，优生优育中心学科3个，博士学位授权点学科22个、硕士学位授权点学科28个。医院现有教职员工3700余名，其中，教授、副教授300余名，博（硕）士导师110余名，博（硕）士学位获得者360余名。202人次在国家和省部级学术团体、期刊任职。医院承担有13个专业、5个层次的临床教学任务。',
          src: xinqiao,
        },
        {
          date: '2019-07',
          name: '大连医科大学附属第一医院',
          desc: '大连医科大学附属第一医院始建于1930年，经过90余年的发展，现已成为集医疗、教学、科研为一体的综合性现代化三级甲等医院。医院建筑面积39.19万平方米，由长春路院区、联合路院区、金普院区、泉涌院区、张前路医院五个院区组成。医院下现编制床位3700张，设教研室29个，三级学科41个，医疗科室82个，医技科室13个，行管科室25个，建有25个教学基地，承担8个层次、18个专业的临床教学任务。中西医结合和临床医学为国家一级学科博士点，临床医学学科评估跃升进入全国前30%。',
          src: dalian,
        },
        {
          date: '2019-07',
          name: '东南大学附属中大医院',
          desc: '东南大学附属中大医院始建于1935年，其前身为中央大学医学院附设医院。经过86年的发展，现已成为集医疗、教学、科研为一体的大型综合性教学医院，是教育部直属“双一流”、“985工程”重点建设的大学附属医院，也是江苏省首批通过卫生部评审的综合性三级甲等医院。东南大学附属中大医院现有编制床位2499张（含江北院区）。 年门急诊量203万人次，年出院病人10.6万余人次。拥有高级职称人员413人，博士365人，硕士673人，博士生导师、硕士生导师181人次。',
          src: nanjingzhongda,
        },
        {
          date: '2019-06',
          name: '新疆维吾尔自治区人民医院',
          desc: '新疆人民医院始建于1934年，是一所集医疗、教学、科研、预防、保健和社区卫生服务为一体的大型综合性“三级甲等医院”。目前编制床位2100张，开放床位2500张。全院现有55个临床科室，19个医技科室，设有博士后科研工作站，4个自治区级中心，8个自治区级研究所，5个自治区级质量控制中心，7个自治区级培训基地。现有职工4100余名，其中副高以上职称490余名。拥有医学博士、硕士400余名，教授、副教授63名，博士生导师5名、硕士生导师72名，专业技术人员占总人数比例的88%，少数民族医师占临床医师总数的47%。',
          src: xinjiang,
        },
        {
          date: '2020-05',
          name: '四川大学华西医院',
          desc: '四川大学华西医院成立于1892年，是中国西部疑难危急重症诊疗的国家级中心，也是世界规模第一的综合性单点医院，拥有中国规模最大、最早整体通过美国病理家学会（CAP）检查认可的医学检验中心。目前院本部床位4100张，以诊治疑难危重疾病为主。四川大学华西医院在2015年公布的中国公立医院社会贡献度排行榜上总分位列全国第一，在复旦大学中国最佳专科声誉和最佳医院排行榜上，连续五年名列全国第二，连续五年名列全国第二，连续多年被独立第三方调查评选为“全国最受欢迎三甲医院” 前十强、“医疗机构最佳雇主”前十强。 ',
          src: huaxi,
        },
        {
          date: '2020-06',
          name: '第四军医大学唐都医院',
          desc: '第四军医大学唐都医院创建于1939年，前身为延安中央医院，是一所集医疗、教学、科研、预防、保健、康复为一体的现代化综合型三级甲等医院。拥有副高级以上专业技术人员359人，其中正高级专家82人，副高级专家277人，博硕士研究生导师180名，教育部“长江学者”特聘教授3名，“973”首席科学家1名，国家“万人计划”科技创新领军人才2名，国家百千万人才工程获得者2名，中国科协“求是奖”获得者2名，中国青年女科学家1名，香江学者3名，军队人员参加省部级及以上学会副主委以上任职100余名。',
          src: tangdu,
        },
        {
          date: '2020-09',
          name: '中南大学湘雅医院',
          desc: '中南大学湘雅医院始建于1906年，是国家卫生健康委员会直管的三级甲等综合医院，是我国重要的临床诊疗、医学教育与科技创新中心。神经内科、神经外科、皮肤科、骨科、呼吸内科、老年病学等专科的诊疗水平和科技影响力位居全国前列。现编制床位3500张，专业技术人员4946人，高级专业技术人员847人，医师中有博士学位人员达83%。现有博士生导师168名，硕士生导师411名。现有中国工程院院士1名，国家级人才项目获得者43名，国家级教学名师2名，国家卫生健康突出贡献中青年专家6名，享受国务院特殊津贴教授73名。 ',
          src: xiangya,
        },
        {
          date: '2019-09',
          name: '广西医科大学第一附属医院',
          desc: '广西医科大学第一附属医院创建于1934年，是三级甲等综合医。医院有临床科室46个，病区69个，医技科室19个，床位2750张。年门急诊服务患者267万人次，年手术5.2万台次，年出院患者12万人次。医院现有在职职工4500人。其中具有正高职称355人、副高职称475人。享受国务院特殊津贴专家32人，广西突出贡献科技人员10人，广西优秀专家17人，广西终身教授2人，广西“十百千人才工程”第二层次人选17人，广西高层次人才认定专家10人，中华医学会全国委员53人，广西医学会各专业委员会主任委员50人、副主任委员70人。',
          src: guangxi,
        },
        {
          date: '2020-09',
          name: '上海交通大学医学院附属仁济医院 ',
          desc: '仁济医院建于1844年，是上海开埠后第一所西医医院，是一个学科门类齐全，集医疗、教学、科研于一体的综合性三级甲等医院。截至2020年底，医院共设有53个临床医技科室，总核定床位2750张；共有正式职工4390人，其中正、副高级职称专家587名，博导136名、硕导166名。在全国医院专科声誉排名中，医院消化内科排第二，风湿免疫科排第三，变态反应科排全国第四、麻醉科排全国第六，泌尿外科排第七，核医学科、生殖医学科排全国第十，肾内科、神经外科、妇产科、放射科、普通外科、检验医学、健康管理等学科均获提名。',
          src: renji,
        },
        {
          date: '2019-11',
          name: '河南省人民医院',
          desc: '河南省人民医院始建于1904年，是一所三级综合医院，是郑州市医保定点医院。河南省人民医院拥有国家临床重点专科建设项目11个，有河南省医学重点学科4个，国家疑难病症诊治能力提升工程项目单位1个，河南省医疗服务能力提升工程建设项目14个，省级医学中心4个。拥有高级职称专家1035人，博士、硕士 2631人；有特聘院士 10 人，中原千人计划专家 11人，享受国务院政府特殊津贴专家36人，首批河南省高层次人才12人；省医学会副主委以上86人，省医师协会常委以上29人，中国医师协会常委以上54人，中华医学会常委以上32人。',
          src: henanrenming,
        },
        {
          date: '2019-09',
          name: '兰州大学第二医院',
          desc: '兰州大学第二医院建于1928年，是集医疗、急救、教学、科研、预防、保健、康复、健康于一体的大型综合性三级甲等医院。现编制1600张，实际开放床位1026张。内设38个临床医技科室，14个行政职能部门。2015年1月，医院整体搬迁新城区，新院区占地面积155亩。医院现有职工1288人，其中高级专业技术人员145人，获第40届国际南丁格尔奖1人，享受国务院政府特殊津贴专家4人，甘肃省优秀专家2人，甘肃省555创新人才1人，甘肃省卫生系统领军人才1人，甘肃省医疗卫生中青年学术技术带头人2人，定西市优秀知识分子拔尖人才13人。',
          src: lanzhou,
        },
        {
          date: '22020-10',
          name: '山东省立医院',
          desc: '山东省立医院始建于1897年，现已发展成为集医疗、科研、教学、预防保健、指导基层为一体的大型综合性三级甲等公立医院。医院拥有双聘院士9人，院士工作站4个，泰山学者攀登计划专家3人，泰山学者特聘专家17人，泰山学者青年计划专家7人，国家百千万人才工程专家2人，国务院政府特殊津贴专家53人，省部级突出贡献中青年专家23人，省医学领军人才5人。63人在国家级专业委员会任职，中华医学会主委1人、副主委4人，省级医学会主委20人、副主委123人。正高级职称专家353人，副高级职称专家528人。　',
          src: shandong,
        },
        {
          date: '2021-10',
          name: '华中科技大学同济医学院附属同济医院',
          desc: '同济医院1900年由德国医师埃里希.宝隆创建于上海，1955年迁至武汉。经过120年的建设与发展，如今是一所集医疗、教学、科研为一体的三级医院。现有“973”项目首席科学家2名，国家杰出青年基金获得者8名、卫生部有突出贡献中青年专家12名、教育部新世纪优秀人才11名，特聘45名院士为同济医院兼职教授，338名教授曾获得博士生导师资格，享受国务院政府特殊津贴者96名。医院设有62个临床和医技科室，拥有国家医学中心1个、国家重点学科11个、国家临床重点专科30个。          ',
          src: tongji,
        },
        {
          date: '2021-8',
          name: '上海中医药大学附属光华医院（风免）',
          desc: '光华中西医结合医院成立于1958年，由上海市税工疗养院、仁爱医院、乐仁医院合并成。经过近60年的建设和发展，光华医院已成为一所治疗类风关、骨关节炎等关节病方面颇具特色的中西医结合的三级甲等专科医院，形成了关节内外科、关节康复科为一体的中西医结合综合治疗特色品牌，具有一定的影响力。2014年7月，长宁区委区政府、上海中医药大学签订区校合作协议，通过双方紧密合作，加快推进光华中西医结合医院成为上海中医药大学附属医院。',
          src: guanghua,
        },
      ],
    };
  }

  componentDidMount() {
    const { data } = this.state;
    console.log(this.props.location.query, 'location.querylocation.querylocation.query');
    let num = 0;
    if (this.props.location.query) {
      num = this.props.location.query;
    }
    const pageSwiper = new Swiper('.development-page .swiper-container', {
      direction: 'vertical',
      loop: true,
      speed: 3000,
      slidesPerView: 7,
      initialSlide: num,
      loopedSlides: 7,
      centeredSlides: true,
      autoHeight: false,
      autoplay: true,
      effect: 'fade',
      on: {
        progress: function (swiperRef, progress) {
          const activeIndex = Math.round(progress * swiperRef.slides.length);
          for (let i = 0; i < swiperRef.slides.length; i++) {
            let slide = swiperRef.slides[i];
            // let scale = 1 - (Math.abs(activeIndex - i)) * 0.1;
            let x = `-${Math.abs(activeIndex - i) * 0.6}rem`;
            if (activeIndex === i) {
              slide.style.transform = `translateX(0)rem`;
              slide.style.opacity = '1';
            } else {
              swiperRef.slides[i].style.transform = `translateX(${x})`;
              slide.style.opacity = `${100 - Math.abs(Math.round(progress * swiperRef.slides.length) - i) * 25}%`;
            }
          }
        },
      },
    });
    // pageSwiper.on('progress', function (swiperRef, progress) {

    // })

    const viewSwiper = new Swiper('.development-view .swiper-container', {
      loop: true,
      speed: 1600,
      noSwiping: true,
      initialSlide: num,
      slidesPerView: 1,
    });
    pageSwiper.on('slideChange', function () {
      let index = pageSwiper.realIndex;
      viewSwiper.slideTo(index + 1);
    });
  }

  render() {
    const { data } = this.state;
    return (
      <div className="section section-cases">
        <div className="earth-box">
          <div className="earth-dot"></div>
          <div className="earth-mark"></div>
          <div className="earth-light"></div>
        </div>
        <div className="cases-container">
          <div className="development-page">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {data.map((item) => (
                  <div className="swiper-slide" key={item.name}>
                    <div className="dev-page">
                      <div className="dev-date">{item.date}</div>
                      <div className="dev-dot"></div>
                      <div className="dev-name">{item.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="dev-title">
            <div className="m-cn">医院介绍</div>
            <div className="m-en">HOSPITAL</div>
          </div>
          <div className="development-view">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {data.map((item) => (
                  <div className="swiper-slide swiper-no-swiping" key={item.name}>
                    <div className="dev-content">
                      <div className="dev-desc">
                        {item.desc}
                        {item.src ? (
                          <div style={{ width: '100%', margin: '0 auto', marginTop: '.4rem' }}>
                            <img src={item.src} style={{ width: '100%', display: 'block', margin: '0 auto' }} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="cases-footer">
          <Footer theme="transparent" />
        </div>
      </div>
    );
  }
}

export default Cases;
