import React from 'react';
import './index.css';
import beian from '../../images/beian.png'

const Footer = (props) => {
  return (
    <div className={`${props.showback ? "newfooter" : "footer"} ${props.theme || ''}`} >
      <div className="container footersize">
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <p>2021 Copyright @森梅医疗科技</p>
          </div>
          <div className="col-xs-12 col-sm-4">
            <p>ADDr：重庆·两江新区·数字经济产业园</p>
          </div>
          <div className="col-xs-12 col-sm-4">
            <a href="https://beian.miit.gov.cn/" target="_blank" className="downline1">渝ICP备18014161号-1</a>
            <img src={beian} />
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002501927" target="_blank" className="downline1">渝公网安备50019002501927号</a>
          </div>
          {/* <div className="col-xs-12 col-sm-3">
            <a href="https://beian.miit.gov.cn/" target="_blank" className="downline1">渝ICP备18014161号-1 </a>
            <img src="https://www.thinmedtech.com/beian.png" />
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002501927" target="_blank" className="downline1">渝公网安备50019002501927号</a>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Footer;