import React from 'react';
import Footer from '../../../components/footer';
import styles from './index.module.css';
import followImg from '../../../images/number/follow.png';
import circular from '../../../images/circular.png';
const Dtx = () => {
  return (
    <div>
      <article className={`${styles.container} ${styles.page1}`}>
        <div className={styles.title}>
          <h2>控钠助手</h2>
          <p>助力肾病患者健康用盐习惯养成</p>
        </div>
        <div className={styles.tips}>
          <p>
            以CBT和ACT理论为基础，结合循证医学的临床治疗方案形成有效的数字干预，从而降低慢性肾脏病患者钠摄入风险
          </p>
          <p>
            以趣味化的创新产品模式，在保证了医学严谨性、有效性的同时最大限度的提高了用户体验，从而提升用户使用的依从性
          </p>
          <p>目前控钠助手已经进入临床试验阶段</p>
        </div>
      </article>
      <article className={`${styles.container} ${styles.page2} `}>
        <div className={`${styles.left} col-sm-12 col-md-6`}></div>
        <div className={`${styles.right} col-sm-12 col-md-6`}>
          <div className={styles.content}>
            <p>针对尿钠过高的肾友初步评估后</p>
            <p> 进行日常摄盐行为干预以降低患者的尿钠值</p>
            <div className={styles.title}>
              <i></i>
              <p>目标</p>
            </div>
          </div>
          <img src={circular} className={styles.icon2} />
          <img src={circular} className={styles.icon3} />
        </div>
        <img src={circular} className={styles.icon1} />
      </article>
      <article className={`${styles.container} ${styles.page3}`}>
        <div className={styles.title}>
          <h2>数字化控钠训练</h2>
        </div>
        <ul className={styles.content}>
          <li>
            <p>基线评估</p>
          </li>
          <li>
            <p>Mini-Cog认知评估</p>
          </li>
          <li>
            <p>控钠知识学习与实践</p>
          </li>
          <li>
            <p>控钠训练进度自动跟踪</p>
          </li>
          <li>
            <p>控钠效果实时反馈</p>
          </li>
        </ul>
        <div className={styles.follow}>
          <img src={followImg} />
          <p>关注公众号了解更多</p>
        </div>
      </article>

      <img src={circular} className={styles.icon4} />
      <img src={circular} className={styles.icon5} />
      <Footer />
    </div>
  );
};

export default Dtx;
