/* 下载app嵌入的页面地址 */
/**
 * 开发环境: https://dev-m-v3.sersmed.cn
 * 测试环境: https://m-v3.sersmed.cn
 * 正式环境: https://m.sersmed.com
 */
const downloadAppOrigin = 'https://m.sersmed.com';

export const D_App_Href = downloadAppOrigin + '/link/doctor?official=1';
export const P_App_Href = downloadAppOrigin + '/link/patient?official=1';

/**
 * 协议文档地址
 */
export const protocols = {
  doctor: {
    agreement: 'https://m.sersmed.com/protocol/doctor/user_agreement.html',
    privacy: 'https://m.sersmed.com/protocol/doctor/privacy_policy.html',
  },
  patient: {
    agreement: 'https://m.sersmed.com/protocol/patient/user_agreement.html',
    privacy: 'https://m.sersmed.com/protocol/patient/privacy_policy.html',
  },
};
