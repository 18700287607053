import React from 'react';
import './index.css';
import background from '../../images/news/zixunback.jpg';
import bottom1 from '../../images/home/bottom.png';
import bottom2 from '../../images/home/bottom2.png';
import bottom3 from '../../images/home/bottom3.png';
import bottom4 from '../../images/home/bottom4.png';
import Footer from '../../components/footer';

const Information = (props) => {
  return (
    <div style={{ position: 'relative' }}>
      <div className={'zixunback1'}>
        <img src={background} />
        <div className={'zixuntext '}>
          <div className={'zixuntext1'}>资讯中心</div>
          <div className={'zixuntext2'}>见证我们的成长</div>
        </div>
      </div>
      <div className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 detailpadiing">
              <div
                className="contact-us-item bottomline"
                onClick={() => {
                  props.history.push('/details1');
                }}
              >
                <div className="m-img newheight">
                  <img src={bottom1} />
                </div>
                <div className="m-cont">
                  <div className="new-text"> 森梅医疗获医联数千万天使轮融资，慢病临床管理工具快速落地</div>
                  <div className="new-des">
                    动脉网第一时间获悉，专注于慢性病临床病情管理解决方案的科技公司森梅医疗完成数千万天使轮融资，由互联网医疗独角兽公司“医联”独家领投。本轮资金将主要用于平台深度开发，地方服务团队及医学团队招募。
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 detailpadiing ">
              <div
                className="contact-us-item bottomline"
                onClick={() => {
                  props.history.push('/details2');
                }}
              >
                <div className="m-img newheight">
                  <img src={bottom2} />
                </div>
                <div className="m-cont">
                  <div className="new-text"> 慢病管理需求增强，「森梅医疗」 想用AI助力患者健康</div>
                  <div className="new-des">
                    36氪近期接触的「森梅医疗」，打算从临床病情管理系统的角度出发，入局慢病管理赛道。之所以选择这个切入口，CEO张敬的想法是，“病人首先是依赖临床科室、相信医
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 detailpadiing">
              <div
                className="contact-us-item bottomline"
                onClick={() => {
                  props.history.push('/details3');
                }}
              >
                <div className="m-img newheight">
                  <img src={bottom3} />
                </div>
                <div className="m-cont">
                  <div className="new-text">平均两周落地一家三甲医院，森梅医疗这样做临床病情管理平台</div>
                  <div className="new-des">
                    从前,护士小张的工作内容是在住院病房执行医嘱,并完成护理工作。现在,小张的任务是在门诊和病房场景中协助医生,对慢性病患者进行全周期管理,她的职业身份也从病房护士调整为专科护士。
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 detailpadiing">
              <div
                className="contact-us-item bottomline"
                onClick={() => {
                  props.history.push('/details4');
                }}
              >
                <div className="m-img newheight">
                  <img src={bottom4} />
                </div>
                <div className="m-cont">
                  <div className="new-text">数字疗法产业中国行(重庆站）：探索数字疗法的“中国实践”</div>
                  <div className="new-des">
                    2021年5月28日，中国第一个数字疗法正式组织——中国康复技术转化及发展促进会数字疗法系统工程专业委员会（筹）（以下简称（专委会（筹）），在重庆开展数字疗法产业中国行活动，汇集数字疗法核心力量，共同探讨产业未来实践。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Information;
