import React from 'react';
import Footer from '../../../components/footer';
import styles from '../dtx/index.module.css';
import styles1 from './index.module.css';
import followImg from '../../../images/number/follow.png';
import circular from '../../../images/circular.png';
const Pew = () => {
  return (
    <div>
      <article className={`${styles.container} ${styles.page1} ${styles1.page1}`}>
        <div className={styles.title}>
          <h2>PEW管理</h2>
          <p>慢性肾脏病患者的营养数字干预</p>
        </div>
        <div className={styles.tips}>
          <p>本软件以《中国慢性肾脏病营养治疗临床实践指南（2021版）》以及《临床营养学》为基础进行营养管理算法设计</p>
          <p>可以按照肾脏病患者的实际个体情况，制定不同的个性化营养管理方案，按照不同的管理方案进行营养管理</p>
          <p>以改善肾脏病患者的营养状况，软件覆盖多类患者居家饮食场景，提供全方面服务与支撑</p>
        </div>
      </article>
      <article className={`${styles.container} ${styles.page2} ${styles1.page2}`}>
        <div className={`${styles.right} ${styles1.right} col-sm-12 col-md-6`}>
          <div className={`${styles.content} ${styles1.content} `}>
            <p>营养不良需要进行营养管理，以及想要科学合理的进行营养自我管理，进行营养预防的病友</p>
            <div className={`${styles.title} ${styles1.title}`}>
              <i></i>
              <p>适应人群</p>
            </div>
          </div>
        </div>
        <div className={`${styles.left} ${styles1.left} col-sm-12 col-md-6`}>
          <img src={circular} className={styles.icon2} />
          <img src={circular} className={styles.icon3} />
        </div>
        <img src={circular} className={styles.icon1} />
      </article>
      <article className={`${styles.container} ${styles.page3} ${styles1.page3}`}>
        <div className={styles.title}>
          <h2>核心功能</h2>
        </div>
        <ul className={styles.content}>
          <li>
            <p>营养-Pew评估</p>
          </li>
          <li>
            <p>营养报告和分析</p>
          </li>
          <li>
            <p>每日定制食谱</p>
          </li>
          <li>
            <p>营养知识学习</p>
          </li>
        </ul>
        <div className={styles.follow}>
          <img src={followImg} />
          <p>关注公众号了解更多</p>
        </div>
      </article>

      <img src={circular} className={styles.icon4} />
      <img src={circular} className={styles.icon5} />
      <Footer />
    </div>
  );
};

export default Pew;
