import React from 'react';
import Footer from '../../components/footer';
import hs from '../../images/news/hs.png';

const Detailsthree = (props) => {
  return (
    <div>
      <div className="totaldetailstyle">
        <div className={'detailtop'} style={{ height: '100px', width: '100vw' }}></div>
        <div className="container">
          <div className="detailstitle">平均两周落地一家三甲医院，森梅医疗这样做临床病情管理平台</div>
          <div className="fromtime">
            <div className="titletime">来源媒体：动脉网</div>
            <div className="titlefrom">发布时间：2019-10-22</div>
          </div>
          <div className="detailtext first-detals-text">
            从前,护士小张的工作内容是在住院病房执行医嘱,并完成护理工作。现在,小张的任务是在门诊和病房场景中协助医生,对慢性病患者进行全周期管理,她的职业身份也从病房护士调整为专科护士。
          </div>
          <div className="detailtext">
            小张所在的陆军军医大学第二附属医院(重庆新桥医院)肾内科设置了慢性肾病(CKD)管理中心,基于连续完整的个人医疗数据和专为慢病管理而设计的软件系统对入组的慢性肾病患者进行个性化、集约化的院内和院外全程管理,该过程包括宣传教育、离院指标、营养评估、各种量表随访评估等院外跟踪,还包括根据个性化病情制定的可能长达一年的复诊复查临床路径,当患者到达临床路径中的复查节点,系统将自动向患者和医护团队发送复查提醒,并追踪执行情况和检查结果评估。
          </div>
          <div className="detial-img-box">
            <img src={hs} className="detals-img" />
          </div>
          <div className="detailtext">
            目前,新桥医院CKD管理中心共有5名全职专科护士,由护士长牵头,半年内完成了超过4000名慢性肾病患者入组管理,中心所使用的软件系统为森梅医疗科技(重庆)有限公司自主开发的《森梅临床病情管理平台》。
          </div>
          <div className="detailtext">
            作为森梅医疗首家落地的样本医院,新桥医院的临床病情管理平台已经上线了慢病管理模块,能够实现病情AI早筛、临床辅助、院外管理和质量控制等功能。
          </div>
          <div className="detailtext">
            据森梅医疗创始人张敬介绍,临床病情管理平台正处于快速迭代中,预计7月底将上线病理及生物样本库管理模块,腹膜透析管理模块也将在8月上线。
          </div>
          <div className="detailtext">
            2018年9月成立至今,森梅医疗自主研发的临床
            病情管理平台于2018年12月在新桥医院上线,从2019年4月开始,陆续在河北医科大学第四医院、大连医科大学附属第一院、东南大学附属中大医院落地,正在执行中的医院还包括解放军总医院、四川大学华西医院、郑州大学附属第一医院、广西医科大学附属第一医院、兰州大学第二医院、新疆自治区人民医院等多个省区的顶级医院。
          </div>
          <div className="detailtext">
            森梅医疗总部位于重庆两江新区,是一家专注于临床病情管理解决方案的科技公司。重组院内结构化病案数据基础,构建院外真实世界结构化通道,让临床可见可管院外行为、院外可得到临床指导,实现疾病的全生命周期管理闭环,从而改善慢性病患者生存质量,是张敬创办森梅医疗的初衷。
          </div>
          <div className="detailtext">
            张敬告诉动脉网,在森梅医疗的产品方案成型之前,他的团队用了长达半年时间跟随不同级别的医生门诊和查房,采集和评估临床医生的诊疗行为习惯,与医生一起挖掘临床真实需求,整理思路打磨产品。
          </div>
          <div className="detailtext">
            “现在产品中嵌入的临床路径、查房入组等功能,还是和新桥医院肾内科的教授们共同梳理出来的,让客户参与到产品设计中,构建产研结合关系,我们希望的是医生愿意主动使用,而不只是做进医院。”高度贴合临床需求的产品功能配置,可能是森梅医疗快速得到国内多家顶级医院认可的重要原因。
          </div>
          <div className="detailtext">
            而正是由于自己的创业设想在新桥医院得到了最大力的支持,张敬选择将公司注册在重庆。张敬表示,森梅医疗选择从患者依从性相对较高的肾脏疾病切入慢病管理,建立自己独特而统一的方法论,后续将在大内科范围逐步向更多的慢性疾病专科推广。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            基于临床病情的院内外一体化管理
          </div>
          <div className="detailtext">
            森梅医疗的临床病情管理平台是一款院内部署临床系统,院外配套患者APP端的闭环产品,临床系统解决内科慢病的早期筛查、临床路径管理、患者家庭管理、患者宣教、病情管控质量等痛点。移动互联网APP能够通达患者,从而形成基于临床病情的院内院外一体化病情管理体系。
          </div>
          <div className="detailtext">
            目前,森梅医疗已经上线了病情早筛系统、临床辅助系统、院外管理系统、质量控制管理等慢病管理功能,下一阶段将上线病理管理、生物样本库管理、腹膜透析管理等板块,能够有效服务于临床科室的经营管理、诊疗效率、医疗质量管理、人才培养和医患关系。
          </div>
          <div className="detailtext">
            临床辅助系统,通过数据可视化和专科数据重构。一方面,根据专科的危险因子差异,对专科所需了解的结构化数据进行重要性分组,提高数据获取便利性以及实现对比查看。另一方面,多亚专科规划临床路径,配合HIS系统实现每次门诊时复查项目提醒并一键开检验单,提高了医生的门诊效率,大大缩短患者等待时间,就医体验大大提高。
          </div>
          <div className="detailtext">
            院外管理系统。构建资料管理库,提供云端资料库共享同步及自定义功能,保障资料来源的权威性。提供批量方案和个性化方案的配置和实施功能,实现高效率的患者管理,同时,根据患者的院外行为监控数据,进行警戒提示,以便医护团队监控所管患者的病情变化。
          </div>
          <div className="detailtext">
            质量控制管理。提供多个维度的统计分析功能,在经营、管理、医学质量等方面辅助临床科室,内容条目包括疑似病患、患者年龄、性别、专科指标控制成效等。
          </div>
          <div className="detailtext">
            病理及生物样本库管理。根据标本种类进行入库管和后续事务管理,包括编号产生、存放位置视图、借出、归还、存量等情况。同时,对采集的标本种类,进行病理诊断的录入,并保留原始凭证,完成病理数据结构化,为后续科研统计工作提供数据基础。
          </div>
          <div className="detailtext">
            腹
             膜透析管理。提供独立的腹膜透析管理工作台,医护团队数据一目了然,并根据医疗场景优化分布式量表评价,缩短门诊单患者时间开销,也让患者参与到自己的病情管理之中。
          </div>
          <div className="detailtext">
            此外,森梅医疗正在基于临床协作业务,构建区域性操作规范和科研数据中心,形成由专科联盟中的各级医院,进行跨地域协作,对患者完成日常复查和阶段性病情评估的新型医疗服务模式,促进了医疗供给侧改革,同时满足患者就医体验和平衡医院之间的利益。
          </div>
          <div className="detailtext">
            目前重庆地区由新桥医院牵头组建专科联盟,第一批有9家地市级三甲医院加入,试运行后将逐步放开,据称后期此联盟规模可能超过30家二甲以上公立医院。而这一模式,未来会在森梅医疗布局的全国所有样本中进行复制。
          </div>
          <div className="detailtext">
            张敬表示我国的医疗体系一直是以诊疗为核心,但流行病学调查显示慢性病的全因死亡率占比达到86.6%,导致的疾病负担占比达到70%,已经成为一个非常严重的社会问题,如何改善迫在眉睫。
          </div>
          <div className="detailtext">
            森梅医疗选择了从大医院切入,慢病管理是体系化运作,我国的国情来看,只有大医院才具有资源和能力构建方法论,形成管控效果,进而逐步向下级医院甚至基层医院进行能力输出。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            数据安全保障强,快速布局和延展服务
          </div>
          <div className="detailtext">
            数据是提供数字化临床管理解决方案的基础。在张敬看来,除了准确把握临床需求,强有力的院内数据安全保障也是森梅医疗得以在医院端快速扩张的重要原因。创办森梅医疗前的近二十年,张敬一直从事B端信息化工作,长期从事金融和电信运营商的信息化建设,深谙信息安全管控的各项规则和细节处理。
          </div>
          <div className="detailtext">
            张敬表示,“医院和金融机构的一个共同点就是十分看重数据安全。”因此,森梅医疗向医院提供了结构清晰、保障功能强大的数据安全架构设计,方案完整。在项目进行的过程中,森梅医疗完全按照医院信息科的安全管控要求实施,同时还以公函的形式提供“实施安全承诺书”、数据安全说明和通信过程安全性说明。
          </div>
          <div className="detailtext">
            通常,应用类软件在对接院内信息化系统时,需要由医院信息科或者信息化系统供应商提供数据接口。而森梅医疗的方案中,数据是留在院内的,并不会将医院的敏感数据传入互联网,让多数医院愿意将信息化系统直接开放,从而减少了大量围绕数据接口展开的中间环节,大大提供了实施效率。
          </div>
          <div className="detailtext">
            “我们的宗旨是围绕医院临床业务提供服务和产生价值,我们是一家医疗业务信息化公司,而非大数据采集公司,我们只需掌握如何使用数据,而无需拥有这些数据。”张敬告诉动脉网,森梅医疗所有的数据处理都在医院内网中进行,帮助医护团队使用好数据,甚至联合开展科研课题或临床研究。
          </div>
          <div className="detailtext">
            目前,森梅医疗启动了成立以来的首轮融资,计划融资800~1000万元,资金将主要用于研发和实施团队扩张和盈利模式的探索。
          </div>
          <div className="detailtext">
            张敬告诉动脉网,森梅医疗正在联合医院客户、保险公司、药企、CRO企业等尝试在处方外流、动态专病保险、GCP研究等方面的可能性,“不过,今年的主要任务仍然是临床病情管理平台在医院和专科联盟层面的推广,我们争取在全国布局20~25家省级顶级医院,以及完成专科联盟模式的探索。”
          </div>
          <div style={{ marginTop: '1rem 0' }}>
            <div className="detail-back">
              <div
                className="detail-back-botton"
                onClick={() => {
                  props.history.push('/details4');
                }}
              >
                浏览下一篇
              </div>
            </div>
            <div className="detail-next">
              <div
                className="detail-next-botton"
                onClick={() => {
                  props.history.push('/news');
                }}
              >
                返回主页
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <Footer />
      </div>
    </div>
  );
};
export default Detailsthree;
