import React from 'react';
import Footer from '../../components/footer';
import detalisone from '../../images/news/detailsone.png';

const Details = (props) => {
  return (
    <div>
      <div className="totaldetailstyle">
        <div className={'detailtop'} style={{ height: '100px', width: '100vw' }}></div>
        <div className="container">
          <div className="detailstitle">森梅医疗获医联数千万天使轮融资，慢病临床管理工具快速落地</div>
          <div className="fromtime">
            <div className="titletime">来源媒体：动脉网</div>
            <div className="titlefrom">发布时间：2020-04-09</div>
          </div>
          <div className="detailtext first-detals-text">
            动脉网第一时间获悉，专注于慢性病临床病情管理解决方案的科技公司森梅医疗完成数千万天使轮融资，由互联网医疗独角兽公司“医联”独家领投。本轮资金将主要用于平台深度开发，地方服务团队及医学团队招募。
          </div>
          <div className="detailtext">
            森梅医疗成立于2018年9月，总部位于重庆两江新区，采用科室共建的形式，以ETL、NLP及AI技术重构院内及真实世界数据，让临床可实现对慢性病患者的疾病全周期闭环管理。
          </div>

          <div className="detailtext">
            2018年12月，森梅医疗自主研发的临床病情管理平台率先在第三军医大学某顶级三甲医院落地。据森梅医疗创始人张敬先生介绍，在首家落地的样本医院，森梅医疗完成了关键风险指标AI筛查算法模型、专科CDR、临床辅助决策（CDSS）、院外管理、质量控制等模块，将临床和真实世界有机连接，用数字化手段将慢病管理从消费级转入严肃医疗流程的同时，打通了患者诊间健康数据盲点。随着活检病理管理、生物样本库管理、科研开放系统等模块的陆续上线，完成了患者主索引（EMPI）建设，该体系将更深度融入临床和科研，服务于更多复杂种类的疾病专科。
          </div>
          <div className="detailtext">
            此外，基于专科医联体的纵向扩展，和基于区域顶级医疗机构优势专科的横向布局，让森梅医疗前期积累的成功经验得以快速复制，以平均每周落地两家三甲医院的速度持续形成庞大的医院用户网络。张敬先生告诉动脉网，森梅医疗2019年半年时间就完成了全国布局，陆续在解放军总医院、四川大学华西医院、第四军医大学唐都医院、大连医科大学附属第一医院、东南大学附属中大医院、广西医科大学附属第一医院、河南省人民医院等几十家地区头部三甲医院落地，正在执行中的医院还包括山东、湖南、江西、安徽、福建、贵州、云南等地区的头部医院。
          </div>
          <div className="detailtext">
            本轮投资的医联战略投资负责人陈宽先生指出，“森梅医疗自主研发的经临床验证有效的慢病管理系统，已落地多家顶级医院，并产生了良好的医患黏性，是数字化慢病管理中具有突破性的尝试。同时，森梅医疗团队具有卓越的专科复制能力，结合医联深耕多年的九大专科慢病管理服务体系，可联合打造多学科慢病管理商业闭环。”
          </div>
          <div className="detailtext">
            张敬先生表示，“医疗是一个有着极强价值主张和专业深度，同时有着庞大产业链条的行业。森梅能快速的迭代产品，证明了团队有着超强的学习能力，得到领域内众多大PI的认可，验证了项目的临床价值，我们觉得非常荣幸，希望借助投资人的资源，朝着严肃医疗的赛道继续前进，朝着临床和科研的深度继续前行。
          </div>
          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            常规临床慢性病管理存在缺口
          </div>
          <div className="detailtext">
            《柳叶刀》公布的一组数据显示，在2016年全球死亡的5470万人中，因慢性病致死的人数占比72.3%，与10年前相比，慢性病致死人数增加16.1%。在我国，《2017中国卫生和计划生育统计年鉴》表明，心脑血管疾病、癌症和慢性呼吸系统疾病是2016年我国城乡居民的主要死因。而《中国疾病预防控制工作进展（2015）》则显示，我国慢性病致死的人数占比为86.6%。
          </div>
          <div className="detailtext">
            无疑，慢性病已经成为人类生命健康的头号杀手。随着人口结构趋于老龄化和吸烟、饮酒、熬夜等不良生活习惯的沉淀，我国慢性病“患病人数多、疾病病程长、医疗成本高、服务需求大”的特点日益凸显。
            2017年1月，国务院办公厅发布《中国防治慢性病中长期规划（2017-2025）》，要求到2020年，慢性病防控环境显著改善，降低因慢性病导致的过早死亡率，力争30~70岁人群因心脑血管疾病、癌症、慢性呼吸系统疾病和糖尿病导致的过早死亡率较2015年降低10%。
          </div>
          <div className="detial-img-box">
            <img src={detalisone} className="detals-img" />
          </div>
          <div className="detailtext">
            慢病管理的主要目标包括：患者教育、使用指导、医生教育、用户互动以及长期随访等几方面。目前来说，慢病管理的痛点主要有三个方面：
          </div>
          <div className="detailtext">
            第一，医生动力不足。长期以来，医生和医院形成了满足急性病治疗的路径依赖，这种工作模式与慢性病管理的需求并不相适应。例如，患者往往根据接受诊疗服务和购买药品而支付费用，而慢病管理过程需要医生执行大量诊疗、处方之外无法计算工作量的工作，会挤出接诊新患者的时间。在这个维度，医生是缺乏慢病管理动力的。
          </div>
          <div className="detailtext">
            第二，患者依从性不足。当前我国已进入慢性病的高负担期，慢性病患病时间长，药物可以一定程度上缓解病症。但更为关键的是患者需要调整与慢病管理需求不相适应的生活习惯，包括按要求接受治疗、频繁对身体的关键指标进行监测、复查等，是一项对患者依从性要求很高的工作，院外缺少监督的患者很难严格执行。
          </div>
          <div className="detailtext">
            第三，费用支付问题。一项由海外媒体在2016年进行的调研数据显示，只有28%的用户愿意为慢病管理付费，且67%的用户的付费意愿低于500元一年。此外，由于慢病管理效果难以评估，无法依据急性病治疗来定性，与医保控费的直接经济联系不明确，且慢病管理市场尚不规范等因素，在医保资金持续承压的背景下，很难直接纳入报销范围。
          </div>
          <div className="detailtext">
            由此可见，单纯的院内优化或者院外规范，难以从根本上提升慢病管理水平，这也是许多信息化工具尝试从专项疾病突破，在患者和医生之间建立直接联系。
          </div>

          <div className="detailtext" style={{ fontWeight: 'bolder', margin: '20px 0' }}>
            嵌入临床全流程的医生工具
          </div>
          <div className="detailtext">
            实际上，借助信息化手段改善诊疗流程的尝试，在患者端是相对容易被接受的。当部分医疗需求得不到有效满足时，患者会主动寻求更新、更高效的解决方案，而要将这种尝试延续到院内，则需要与临床诊疗的规则、需求等高度契合
          </div>
          <div className="detailtext">
            森梅医疗临床病情管理平台在院内部署临床系统，配套院外患者手机端形成闭环产品。临床系统端提供慢病的早期筛查、临床路径管理、患者家庭管理、患者宣教、病情管控质量等功能，成为临床医生好用的慢病患者管理工具。
          </div>
          <div className="detailtext">
            具体而言，森梅医的临床系统中已经上线了病情早筛、临床辅助、院外管理、质量控制病理业务管理、生物样本库管理等多元化的模块板块，能够综合解决临床科室的诸如经营管理、诊疗效率、医疗质量管理、科研效率等痛点。
          </div>
          <div className="detailtext">
            在模式复制方面，森梅医疗正在基于临床协作业务，构建区域性操作规范和科研数据中心，形成由专科联盟中的各级医院，进行跨地域协作，对患者完成日常复查和阶段性病情评估的新型医疗服务模式，促进了医疗供给侧改革，同时满足患者就医体验和平衡医院之间的利益。
          </div>
          <div className="detailtext">
            张敬先生告诉动脉网，在配合临床开展慢病管理的同时，森梅医疗正在尝试引入更多的资源，服务于临床和患者。包括输出数字化临床科研服务，探索慢性病带病保险新规则，IVD及第三方检验等。“医疗领域有庞大的产业链资源，我们要在尊重科学和伦理的基础上，用好这些资源，服务于临床和患者，也为社会健康事业做出微薄的贡献”张敬先生表示。
          </div>
          <div style={{ marginTop: '1rem 0' }}>
            <div className="detail-back">
              <div
                className="detail-back-botton"
                onClick={() => {
                  props.history.push('/details2');
                }}
              >
                浏览下一篇
              </div>
            </div>
            <div className="detail-next">
              <div
                className="detail-next-botton"
                onClick={() => {
                  props.history.push('/news');
                }}
              >
                返回主页
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <Footer />
      </div>
    </div>
  );
};
export default Details;
