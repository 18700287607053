import React from 'react';
import Footer from '../../components/footer';
import jqr from '../../images/news/jqr.png';

const Detailstwo = (props) => {
  return (
    <div>
      <div className="totaldetailstyle">
        <div className={'detailtop'} style={{ height: '100px', width: '100vw' }}></div>
        <div className="container">
          <div className="detailstitle">慢病管理需求增强，「森梅医疗」 想用AI助力患者健康</div>
          <div className="fromtime">
            <div className="titletime">来源媒体：36氪</div>
            <div className="titlefrom">发布时间：2019-10-22</div>
          </div>
          <div className="detailtext first-detals-text">
            36氪近期接触的「森梅医疗」，打算从临床病情管理系统的角度出发，入局慢病管理赛道。之所以选择这个切入口，CEO张敬的想法是，“病人首先是依赖临床科室、相信医生的，临床的需求更像是金字塔的塔尖。”
          </div>
          <div className="detailtext">
            森梅医疗的做法是，在院内搭建森梅临床系统（HIS/LIS/PACS），帮助科室提高效率；在院外提供森梅考拉APP，把患者的健康数据沉淀下来，帮助疾病治疗。
          </div>
          <div className="detial-img-box">
            <img src={jqr} className="detals-img" />
          </div>
          <div className="detailtext">
            事实上，很多医疗信息系统面临的问题在于，即使通过协议、免费入驻等等方式进入了医院，但是医生仍然不愿意使用，根本问题在于系统设计不合理。
          </div>
          <div className="detailtext">
            在这一方面，森梅医疗具备一定优势，其功能偏向临床，贴合医生的临床需求。CEO张敬具有三年的互联网医疗的咨询顾问经历，曾任商务总监和产品总监，陪诊多名头部三甲医院教授一年以上，因此熟悉临床工作，在产品设计上具有优势
          </div>
          <div className="detailtext">那么，森梅医疗有哪些产品优势，使得医生愿意使用它的系统呢？</div>
          <div className="detailtext">
            其次，对于慢性病来说，院外管理是非常重要的环节。传统模式通常依赖患者自身管理，森梅系统帮助医生跟踪患者的院外数据，如遇数据异常还有安全警告，提醒医院及时回访。
          </div>
          <div className="detailtext">
            另外，系统还具备AI病情早筛功能，基于全院临床数据的单病种特征数据挖掘算法，帮助精准测算专科病情特征数据链，有利于病情早发现早治疗，解决专病患者知晓率低、漏诊错诊的就诊痛点。
          </div>
          <div className="detailtext">
            那么，森梅医疗打算如何进入医院？在很多医院已经具有医疗信息系统的背景下，森梅医疗怎么进入医院呢？
          </div>
          <div className="detailtext">
            对于这个问题，森梅医疗CEO张敬表示，很多医院现有的是院内信息系统，而森梅系统则是注重院外，注重临床能力向患者端的输出，这是两种分支。森梅医疗和院内信息系统是共存的状态，可以互补，森梅系统辅助提供患者的过往指标、过往病历，医生的诊疗操作仍然使用院内信息系统，不会改变医生原来的使用路径。
          </div>
          <div className="detailtext">
            传统系统进入医院，通常要走招标采购的路径，面对医院信息科，通常要半年到一年时间才能完成采购。森梅医疗的做法是从临床专科进入医院，临床专科认可后，再由专科主任向院长提要求。医院使用是免费的，从第二年开始收取少量年费。正是因为这个策略，森梅医疗的推广速度比较快，今年4月开始对外推广，目前已经在4家三甲医院落地运行，分别是东南大学附属中大医院、大连医科大附一、河北医科大第四医院、陆军军医大学新桥医院。
          </div>
          <div className="detailtext">
            另外值得关注的，是森梅医疗能否快速铺开局面，进入更多医院。森梅医疗与一些药企建立了合作推广关系，药企认识到科室的慢病管理也有益于产品的回购率。另一方面，森梅医疗首先切入头部三甲医院，由头部医院通过专科联盟去推广到地方三甲医院。
          </div>
          <div className="detailtext">
            盈利模式方面，森梅医疗对医院会收取较低的年费，未来主要倾向让患者端收费。患者的付费医院来于，医院会给患者安排好复诊，患者不用重新挂号，系统还会帮助患者做长期的病情管理。其他盈利点是，基于病情动态的商业保险合作，帮助药企主动招募患者和随访跟踪。
          </div>
          <div className="detailtext">
            目前，森梅医疗正在寻求天使轮融资，目标融资额为800-1000万元。本阶段目标是建立全国18-25家地区头部医院，探索专科联盟推广模式，建立1个专科联盟，专科联盟包含9家三级以上公立医院。
          </div>
          <div style={{ marginTop: '1rem 0' }}>
            <div className="detail-back">
              <div
                className="detail-back-botton"
                onClick={() => {
                  props.history.push('/details3');
                }}
              >
                浏览下一篇
              </div>
            </div>
            <div className="detail-next">
              <div
                className="detail-next-botton"
                onClick={() => {
                  props.history.push('/news');
                }}
              >
                返回主页
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <Footer />
      </div>
    </div>
  );
};
export default Detailstwo;
