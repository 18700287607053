import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './index.css';
import fixingnail from '../../images/fixingnail.png';
import totop from '../../images/home/totop.png';
import themebottom1 from '../../images/themebottom1.png';
import themebottom2 from '../../images/themebottom2.png';
import themebottom3 from '../../images/themebottom3.png';
import down1 from '../../images/down1';
import down2 from '../../images/down2';
import down3 from '../../images/down3';
import { Dropdown } from 'antd';
const NavigationBar = (props) => {
  let location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(window.location?.hash);
  console.log('selected: ', selected);
  const [open, setOpen] = useState(false); /* 移动端打开菜单 */
  const [fixedHeader, setFixedHeader] = useState(false); /* 固定导航 */
  const [isDetails, setIsDetails] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const navList = [
    { name: '首页', url: '/home' },
    {
      name: '数字干预',
      url: '/number',
      children: [
        { name: '控钠助手', url: '/number/dtx' },
        { name: 'PEW管理', url: '/number/pew' },
      ],
    },
    { name: '资讯', url: '/news' },
    { name: '案例', url: '/cases' },
    { name: '联系我们', url: '/contact' },
    { name: '下载APP', url: '/download' },
  ];

  useEffect(() => {
    setSelected(`${location.pathname}`);
    if (props.location.pathname.indexOf('/details') != -1) {
      setIsDetails(true);
    }
  }, [location]);
  useEffect(() => {
    if (props.location.pathname.indexOf('/details') != -1) {
      setIsDetails(true);
    } else {
      setIsDetails(false);
    }
  }, [props.location.pathname]);

  const handleScroll = (e) => {
    const win_width = document.documentElement.clientWidth;
    const scrollHeight = document.documentElement.scrollTop;
    // console.log(scrollHeight)
    const offsetHeight = win_width > 992 ? 100 : 50;
    if (scrollHeight > offsetHeight) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div className={`header ${open ? 'open-menu' : ''} ${fixedHeader || isDetails ? 'nav-fiex' : ''}`}>
        <div className={`navbar-container`}>
          <div className="navbar">
            <a
              href="/home"
              className={document.documentElement.scrollTop > 1000 ? 'whitelogo' : 'logo'}
              alt="森梅医疗科技"
            ></a>
            <div
              className="navbar-togger"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <div className="icon_bar"></div>
              <div className="icon_bar"></div>
              <div className="icon_bar"></div>
            </div>
          </div>
          <div className="nav-menu">
            <ul className="nav-list">
              {navList.map((item) =>
                item.children?.length > 0 ? (
                  <Dropdown
                    menu={{
                      items: item.children.map((e) => ({
                        key: e.url,
                        label: (
                          <>
                            <div
                              onClick={() => {
                                setOpen(false);
                                history.push(e.url);
                              }}
                            >
                              {e.name}
                            </div>
                          </>
                        ),
                      })),
                    }}
                  >
                    <li key={item.name} className={selected.includes(item.url) ? 'active' : ''}>
                      <a onClick={(e) => e.preventDefault()}>
                        <span>{item.name}</span>
                      </a>
                    </li>
                  </Dropdown>
                ) : (
                  <li
                    key={item.name}
                    className={selected === item.url ? 'active' : ''}
                    onClick={() => {
                      history.push(item.url);
                      setOpen(false);
                      if (item.url != '/home') {
                        setIsHome(false);
                      } else {
                        setIsHome(true);
                      }
                    }}
                  >
                    <a>
                      <span>{item.name}</span>
                    </a>
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
      {props.children}
      <div
        className={`is-show fixing-nail ${document.documentElement.scrollTop < 100 ? 'noshow' : ''}`}
        onClick={() => {
          var goTop = setInterval(scrollMove, 10);
          function scrollMove() {
            document.documentElement.scrollTop = document.documentElement.scrollTop / 1.1;
            if (document.documentElement.scrollTop < 1) clearInterval(goTop);
          }
        }}
      >
        <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
          <div>
            <img src={totop} style={{ display: 'block', width: '100%' }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NavigationBar;
