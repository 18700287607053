import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import NavigationBar from '../components/NavigationBar/index';
import Contact from '../pages/contact';
import Information from '../pages/information';
import Details from '../pages/information/details';
import Cases from '../pages/cases';
import ScrollToTop from '../components/ScrollToTop';
import Detailsthree from '../pages/information/details3';
import Detailstwo from '../pages/information/details2';
import Detailsfour from '../pages/information/details4';
import Dtx from '../pages/number/dtx';
import Pew from '../pages/number/pew';
import Download from '../pages/download';

const router = (
  <BrowserRouter>
    <App>
      <ScrollToTop>
        <Switch>
          <NavigationBar>
            <Route path="/home" component={Home}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/news" component={Information}></Route>
            <Route path="/Details1" component={Details}></Route>
            <Route path="/Details2" component={Detailstwo}></Route>
            <Route path="/Details3" component={Detailsthree}></Route>
            <Route path="/Details4" component={Detailsfour}></Route>
            <Route path="/Cases" component={Cases}></Route>
            <Route path="/number/dtx" component={Dtx}></Route>
            <Route path="/number/pew" component={Pew}></Route>
            <Route path="/download" component={Download}></Route>
            <Redirect from="" to="/home" />
          </NavigationBar>
        </Switch>
      </ScrollToTop>
    </App>
  </BrowserRouter>
);
export default router;
