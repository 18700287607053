import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer';
import styles from './index.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { D_App_Href, P_App_Href, protocols } from '../../api/config';
import logoGreen from '../../images/download/logoGreen.png';
import logoBlue from '../../images/download/logoBlue.png';
import iconIphone from '../../images/download/icon-iphone.png';
import iconAndroid from '../../images/download/icon-android.png';
import appGreen from '../../images/download/appGreen.png';
import appBlue from '../../images/download/appBlue.png';
import { downloadApk } from '../../utils/common';

var scheme = {
  doctor: 'sersmed-doctor://',
  patient: 'sersmed-patient://',
  patienthuaxi: 'sersmed-patienthuaxi://',
  pharmacy: 'sersmed-pharmacy://',
};

const Download = () => {
  const [index, setIndex] = useState(0); //0 d-app 1 p-app

  useEffect(() => {
    const d = document.getElementsByClassName('QRCODE0');
    Array.from(d).map((e) => new window.QRCode(e, D_App_Href));
    const p = document.getElementsByClassName('QRCODE1');
    Array.from(p).map((e) => new window.QRCode(e, P_App_Href));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles['web']}>
        <div className={styles['swiper']}>
          <Swiper
            className={styles['swiper-container']}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={
              (true,
              {
                delay: 10000,
                disableOnInteraction: false,
              })
            }
            speed={2000}
            style={{ width: '100%', height: '100%' }}
          >
            {['肃医医生端', '肃医患者端'].map((e, index) => (
              <SwiperSlide key={e}>
                <div className={styles['dp-web-container']}>
                  <div className={styles['content']}>
                    <div className={styles['left']}>
                      <img src={[logoGreen, logoBlue][index]} alt="" />
                      <h1>{e}</h1>
                      <p>
                        {
                          [
                            '一款专为医生打造的轻量级慢病管理服务平台，提供标准化诊疗路径，覆盖患者全诊疗周期，通过病程管理体系提高患者依从性，增强医患粘性。',
                            '针对慢性病患者设计的一款康复管理平台，该平台以慢病智能化管理路径为核心，并融入营养、运动、控钠等多种数字疗法手段助力患者的疾病康复',
                          ][index]
                        }
                      </p>
                      <div className={styles['qrcode-content']}>
                        <div className={`${styles['qrcode']} QRCODE${index}`} id={'QRCODE' + index}></div>
                        <div className={styles['down']}>扫码下载</div>
                      </div>
                      <div className={styles['btns']}>
                        <a
                          href={
                            [
                              'https://apps.apple.com/us/app/id1502509307',
                              'https://apps.apple.com/us/app/id1501990781',
                            ][index]
                          }
                          className={styles['iosBtn']}
                        >
                          <img src={iconIphone} />
                          <span>iPhone下载</span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => downloadApk(index == 1 ? 1 : 2)}
                          className={styles['androidBtn']}
                        >
                          <img src={iconAndroid} />
                          <span>Android下载</span>
                        </a>
                      </div>
                      <div className={styles['agreement']}>
                        <a href={protocols[['doctor', 'patient'][index]].agreement}>《用户协议》</a>
                        <a href={protocols[['doctor', 'patient'][index]].privacy}>《隐私政策》</a>
                      </div>
                    </div>
                    <div className={styles['right']}>
                      <img src={[appGreen, appBlue][index]} alt="" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Footer theme="transparent" />
        </div>
      </div>

      <div className={styles['h5']}>
        {/MicroMessenger/i.test(navigator.userAgent) && (
          <div className={styles['weixin']}>
            <div className={styles['weixin-tip']}>
              <div className={styles['weixin-tip-content']}>
                请在菜单中选择在浏览器中打开,
                <br />
                以完成下载
              </div>
            </div>
            <div
              className={`${styles['weixin-tip-img']} ${
                styles[/iPhone/i.test(navigator.userAgent) ? 'iphone' : 'android']
              }`}
            ></div>
            :
          </div>
        )}
        <div className={styles['download']}>
          <div className={styles['select-content']}>
            {['医生端', '患者端'].map((e, i) => (
              <span onClick={() => setIndex(i)} className={styles[index == i ? 'active' : '']} key={i}>
                {e}
              </span>
            ))}
          </div>
          <div className={styles['logo-content']}>
            <img className={styles['logo']} src={[logoGreen, logoBlue][index]} />
            <h1>{['肃医医生端', '肃医患者端'][index]}</h1>
            <p>致力于改善患者生存质量</p>
          </div>
          <div className={styles['bottom']}>
            <div id="btns">
              {!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ? (
                <a
                  className={styles['iosBtn']}
                  href={
                    ['https://apps.apple.com/us/app/id1502509307', 'https://apps.apple.com/us/app/id1501990781'][index]
                  }
                >
                  <img src={iconIphone} />
                  <span>iPhone下载</span>
                </a>
              ) : (
                <a
                  className={styles['androidBtn']}
                  href="javascript:void(0)"
                  onClick={() => downloadApk(index == 1 ? 1 : 2)}
                >
                  <img src={iconAndroid} />
                  <span>Android下载</span>
                </a>
              )}
              <a className={styles['openBtn']} href={scheme[index]}>
                已有安装，立即打开
              </a>
            </div>
          </div>
          <footer>
            <a href={protocols[['doctor', 'patient'][index]].agreement}>《用户协议》</a>
            <a href={protocols[['doctor', 'patient'][index]].privacy}>《隐私政策》</a>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Download;
