import React, { useEffect, useState, useCallback, useRef } from 'react';
import './index.css';
import { WOW } from 'wowjs';
// import { Button } from "antd"
import down1 from '../images/down1';
import down2 from '../images/down2';
import down3 from '../images/down3';
import linchuang from '../images/home/linchuang.jpg';
import manbing from '../images/home/manbing.jpg';
import digitalTherapy from '../images/home/digitalTherapy.jpg';
import qianghua from '../images/home/qianghua.jpg';
import fuwu from '../images/home/fuwu.jpg';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import phoneAcom from '../images/home/phoneAcom.png';
import Summary from '../images/home/Summary.png';
import dtx_na from '../images/home/dtx-na.png';
import pew from '../images/home/pew.png';
import toubu from '../images/home/toubu.png';
import righyarrow from '../images/righyarrow.png';
import bianzu14 from '../images/bianzu14.png';
import jiahao from '../images/jiahao.png';
import bianzu15 from '../images/bianzu15.png';
import lujing23 from '../images/home/lujing23.png';
import mapofchina from '../images/home/mapofchina.png';
import dingwei from '../images/home/dingwei.png';
import bottom1 from '../images/home/bottom.png';
import bottom2 from '../images/home/bottom2.png';
import bottom3 from '../images/home/bottom3.png';
import bottom4 from '../images/home/bottom4.png';
import bianzutop from '../images/home/bianzutop.png';
import bianzubuttom from '../images/home/bianzubuttom.png';
import bianzumid from '../images/home/bianzumid.png';
import smallliucheng from '../images/home/smallliucheng.png';
import smallmap from '../images/home/smallmap.png';
import bottomtops from '../images/home/bottomtops.png';
import Footer from '../components/footer';
import gsjjback from '../images/home/gsjjback.jpg';
import checkmore from '../images/checkmore.png';
import circular from '../images/circular.png';
import themebottom1 from '../images/themebottom1.png';
import themebottom2 from '../images/themebottom2.png';
import themebottom3 from '../images/themebottom3.png';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Navigation]);

const Home = (props) => {
  const qingliang = useRef();
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    console.log(document.documentElement.clientWidth, 'width');
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1300) {
      document.getElementById('whitebottom').style.display = 'none';
      document.getElementById('blackbottom').style.display = 'block';
    } else {
      document.getElementById('whitebottom').style.display = 'block';
      document.getElementById('blackbottom').style.display = 'none';
    }
    getmove();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const getmove = () => {
    var wow = new WOW({
      boxClass: 'textLeft',
      animateClass: 'textLeft',
      offset: 200,
      mobile: true,
      live: true,
    });
    wow.init();
  };
  useEffect(() => {
    let hehe = window.addEventListener('scroll', getstyle);
    return () => {
      hehe = window.removeEventListener('scroll', getstyle);
    };
  }, []);
  const getstyle = () => {
    if (document.documentElement.scrollTop < 100) {
      if (document.documentElement.clientWidth > 1300) {
        document.getElementById('whitebottom').style.display = 'none';
        document.getElementById('blackbottom').style.display = 'block';
      } else {
        document.getElementById('whitebottom').style.display = 'block';
        document.getElementById('blackbottom').style.display = 'none';
      }
    } else if (
      document.documentElement.scrollTop > 100 &&
      document.documentElement.scrollTop < document?.getElementsByClassName('zixunback')[0]?.offsetHeight
    ) {
      document.getElementById('whitebottom').style.display = 'block';
      document.getElementById('blackbottom').style.display = 'none';
    } else if (
      document.documentElement.scrollTop > document?.getElementsByClassName('zixunback')[0]?.offsetHeight &&
      document.documentElement.scrollTop <
      document?.getElementsByClassName('zixunback')[0]?.offsetHeight +
      document?.getElementsByClassName('total-gsjj')[0]?.offsetHeight +
      document?.getElementsByClassName('topsanjiao')[0]?.offsetHeight
    ) {
      document.getElementById('whitebottom').style.display = 'none';
      document.getElementById('blackbottom').style.display = 'block';
    } else if (
      document.documentElement.scrollTop <
      document?.getElementsByClassName('zixunback')[0]?.offsetHeight +
      document?.getElementsByClassName('total-gsjj')[0]?.offsetHeight +
      document?.getElementsByClassName('lunbostyle')[0]?.offsetHeight +
      document?.getElementsByClassName('total-liucheng')[0]?.offsetHeight +
      document?.getElementsByClassName('swiper-bottom')[0]?.offsetHeight +
      100 &&
      document.documentElement.scrollTop >
      document?.getElementsByClassName('zixunback')[0]?.offsetHeight +
      document?.getElementsByClassName('total-gsjj')[0]?.offsetHeight +
      document?.getElementsByClassName('topsanjiao')[0]?.offsetHeight
    ) {
      document.getElementById('whitebottom').style.display = 'block';
      document.getElementById('blackbottom').style.display = 'none';
    } else if (
      document.documentElement.scrollTop >
      document?.getElementsByClassName('zixunback')[0]?.offsetHeight +
      document?.getElementsByClassName('total-gsjj')[0]?.offsetHeight +
      document?.getElementsByClassName('lunbostyle')[0]?.offsetHeight +
      document?.getElementsByClassName('total-liucheng')[0]?.offsetHeight +
      document?.getElementsByClassName('swiper-bottom')[0]?.offsetHeight +
      100
    ) {
      document.getElementById('whitebottom').style.display = 'none';
      document.getElementById('blackbottom').style.display = 'none';
    }
  };
  const renderMore = (path) => {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => props.history.push(path)}>
        了解更多 ＞
      </div>
    );
  };
  return (
    <div>
      <div
        className="animate-bounce-down1 is-show"
        style={{
          position: 'fixed',
          top: '86vh',
          textAlign: 'center',
          alignSelf: 'center',
          width: '100%',
          zIndex: '9999',
        }}
        onClick={() => {
          if (document.documentElement.scrollTop < document.getElementsByClassName('zixunback')[0].offsetHeight - 100) {
            let goTop1 = setInterval(scrollMove, 1);
            function scrollMove() {
              document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
              if (
                document.documentElement.scrollTop >
                document.getElementsByClassName('zixunback')[0].offsetHeight - 100
              ) {
                clearInterval(goTop1);
              }
            }
          } else if (
            document.getElementsByClassName('zixunback')[0].offsetHeight - 100 < document.documentElement.scrollTop &&
            document.documentElement.scrollTop <
            document.getElementsByClassName('zixunback')[0].offsetHeight +
            document.getElementsByClassName('total-gsjj')[0].offsetHeight +
            document.getElementsByClassName('topsanjiao')[0].offsetHeight -
            190
          ) {
            let goTop2 = setInterval(scrollMove2, 1);
            function scrollMove2() {
              document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
              if (
                document.documentElement.scrollTop >
                document.getElementsByClassName('zixunback')[0].offsetHeight +
                document.getElementsByClassName('total-gsjj')[0].offsetHeight +
                document.getElementsByClassName('topsanjiao')[0].offsetHeight -
                190
              ) {
                clearInterval(goTop2);
              }
            }
          } else if (
            document.getElementsByClassName('zixunback')[0].offsetHeight +
            document.getElementsByClassName('total-gsjj')[0].offsetHeight +
            document.getElementsByClassName('topsanjiao')[0].offsetHeight -
            190 <
            document.documentElement.scrollTop &&
            document.documentElement.scrollTop <
            document.getElementsByClassName('zixunback')[0].offsetHeight +
            document.getElementsByClassName('total-gsjj')[0].offsetHeight +
            document.getElementsByClassName('lunbostyle')[0].offsetHeight +
            document.getElementsByClassName('swiper-bottom')[0].offsetHeight -
            200
          ) {
            let goTop3 = setInterval(scrollMove3, 1);
            function scrollMove3() {
              document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
              if (
                document.documentElement.scrollTop >
                document.getElementsByClassName('zixunback')[0].offsetHeight +
                document.getElementsByClassName('total-gsjj')[0].offsetHeight +
                document.getElementsByClassName('lunbostyle')[0].offsetHeight +
                document.getElementsByClassName('swiper-bottom')[0].offsetHeight -
                200
              )
                clearInterval(goTop3);
            }
          } else if (
            document.documentElement.scrollTop <
            document.getElementsByClassName('zixunback')[0].offsetHeight +
            document.getElementsByClassName('total-gsjj')[0].offsetHeight +
            document.getElementsByClassName('lunbostyle')[0].offsetHeight +
            document.getElementsByClassName('total-liucheng')[0].offsetHeight +
            document.getElementsByClassName('swiper-bottom')[0].offsetHeight -
            50 &&
            document.documentElement.scrollTop >
            document.getElementsByClassName('zixunback')[0].offsetHeight +
            document.getElementsByClassName('total-gsjj')[0].offsetHeight +
            document.getElementsByClassName('lunbostyle')[0].offsetHeight +
            document.getElementsByClassName('swiper-bottom')[0].offsetHeight -
            200
          ) {
            let goTop4 = setInterval(scrollMove4, 1);
            function scrollMove4() {
              document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
              if (
                document.documentElement.scrollTop >
                document.getElementsByClassName('zixunback')[0].offsetHeight +
                document.getElementsByClassName('total-gsjj')[0].offsetHeight +
                document.getElementsByClassName('lunbostyle')[0].offsetHeight +
                document.getElementsByClassName('total-liucheng')[0].offsetHeight +
                document.getElementsByClassName('swiper-bottom')[0].offsetHeight -
                30
              )
                clearInterval(goTop4);
            }
          } else if (
            document.documentElement.scrollTop >
            document.getElementsByClassName('zixunback')[0].offsetHeight +
            document.getElementsByClassName('total-gsjj')[0].offsetHeight +
            document.getElementsByClassName('lunbostyle')[0].offsetHeight +
            document.getElementsByClassName('total-liucheng')[0].offsetHeight +
            document.getElementsByClassName('swiper-bottom')[0].offsetHeight -
            30
          ) {
            var goTop = setInterval(scrollMove, 1);
            function scrollMove() {
              document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
              if (
                document.documentElement.scrollTop + document.documentElement.clientHeight ==
                document.documentElement.scrollHeight
              )
                clearInterval(goTop);
            }
          }
        }}
      >
        <div id="whitebottom">
          <div>
            <img src={themebottom2} style={{ width: '.30rem', display: 'block', margin: '0 auto' }} />
          </div>
          <div>
            <img
              src={themebottom1}
              style={{
                width: '.30rem',
                marginTop: '-20px',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </div>
          <div>
            <img
              src={themebottom3}
              style={{
                width: '.30rem',
                marginTop: '-40px',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </div>
        </div>
        <div id="blackbottom">
          <div>
            <img src={down3} style={{ width: '.30rem', display: 'block', margin: '0 auto' }} />
          </div>
          <div>
            <img
              src={down2}
              style={{
                width: '.30rem',
                marginTop: '-20px',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </div>
          <div>
            <img
              src={down1}
              style={{
                width: '.30rem',
                marginTop: '-40px',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </div>
        </div>
      </div>
      <div className="zixunback">
        {/* <img src={docter} className={'syimg'} /> */}
        {/* <img src={size.width > 991 ? docter : smalldoctor} className={'syimg'} /> */}
      </div>
      <div className="firsttitle">
        <h1 className={'wow textLeft sytext1'}>致力于提高患者生存质量</h1>
        <h2 className={'wow textLeft sytext2'}>
          构建常态化<span style={{ fontWeight: 'bold' }}>慢性病病情</span>
          管理
        </h2>
        <h4 className={'wow textLeft sytext3'}>
          以患者病情管理为切入点，以软件系统为抓手，建立真实世界临床评估，优化科室经营，提高科研效率
        </h4>
        <div className="downbottom is-show textLeft">
          <div
            className="bottomsstyle"
            onClick={() => {
              var goTop = setInterval(scrollMove, 1);
              function scrollMove() {
                document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
                if (
                  document.documentElement.scrollTop >
                  document.getElementsByClassName('zixunback')[0].offsetHeight - 100
                )
                  clearInterval(goTop);
              }
            }}
          >
            点击开启新时代
          </div>
          {/* <div className={"animate-bounce-down1"} onClick={() => {

          var goTop = setInterval(scrollMove, 1);
          function scrollMove() {
            document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
            if (document.documentElement.scrollTop > document.getElementsByClassName("zixunback")[0].offsetHeight - 100) clearInterval(goTop);
          }
        }}>
          <div> <img src={down3} style={{ width: ".30rem", }} /></div>
          <div> <img src={down2} style={{ width: ".30rem", marginTop: "-20px" }} /></div>
          <div> <img src={down1} style={{ width: ".30rem", marginTop: "-40px" }} /></div>
        </div> */}
        </div>
      </div>
      <div style={{ position: 'relative' }} className="is-show ">
        <div style={{ position: 'absolute', top: '-4rem', width: '.5rem' }}>
          <img src={circular} style={{ width: '100%' }} />
        </div>
        <div style={{ position: 'absolute', width: '1.08rem', right: '1rem' }}>
          <img src={circular} style={{ width: '100%' }} />
        </div>
        <div
          style={{
            position: 'absolute',
            width: '.96rem',
            top: '1rem',
            left: '.5rem',
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
      </div>
      <div className="total-gsjj">
        <div className="gsjj">
          <div className="gsjjleftbox">
            <img src={gsjjback} className={'bigimg'} />
          </div>
          <div className={'righttext'}>
            <div className={'wow textLeft'}>
              <div className={'righttitle'}>森梅医疗科技(重庆)有限公司</div>
              <div
                style={{
                  fontSize: '.2rem',
                  marginTop: '.6rem',
                  textAlign: 'left',
                  color: '#676C7A',
                }}
              >
                <p>森梅医疗科技是一家专注于慢性病临床病情管理解决方案的科技公司。</p>
                <br />
                <p>公司以改善患者生存质量为使命，以尊重科学、尊重伦理为价值观。</p>
                <br />
                <p>
                  公司自主研发的临床病情管理平台，以解决科室慢病的早期筛查、临床路径管理、患者家庭管理、患者院外宣教、病情管控质量等痛点，同时建立移动互联网APP通达患者，构建基于临床病情的院内院外一体化病情管理体系。
                </p>
              </div>
            </div>
            <div className="imglist">
              <img src={linchuang} className={'bianzu'} />
              <img src={manbing} className={'bianzu'} />
              <img src={digitalTherapy} className={'bianzu'} />

              <img src={qianghua} className={'bianzu'} />
              <img src={fuwu} className={'bianzu'} />
            </div>
          </div>
        </div>
      </div>
      <div className="noswiper">
        <div className={'newleft1  wow textLeft'}>
          <div style={{ fontSize: '.8rem', color: 'white', opacity: '14%' }}>PRODUCT</div>
          <div
            style={{
              fontSize: '.6rem',
              color: 'white',
              marginTop: '-1rem',
              // marginLeft: '1rem',
            }}
          >
            产品全景
          </div>
          <div style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>专业版</div>
          <div style={{ color: 'white', marginTop: '.4rem', fontWeight: 'normal' }}>
            打造全国标杆级慢病管理的全场景临床抓手工具
          </div>
          <ul className="chanpin">
            <li>
              <div>适用范围</div>
              <div>自建团队开展慢病管理同座的三甲医院</div>
            </li>
            <li>
              <div>产品载体</div>
              <div>内网系统+患者App</div>
            </li>
            <li>
              <div>人员要求</div>
              <div>需要设置专科护士</div>
            </li>
            <li>
              <div>实施部署</div>
              <div>需要院内系统接口，实施周期一月左右</div>
            </li>
          </ul>
        </div>
        <div style={{ padding: '20px', width: '100%' }}>
          <img src={phoneAcom} style={{ width: '100%' }} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ zIndex: '10', marginLeft: '.5rem' }}>
            <div style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>轻量版</div>
            <div
              style={{
                color: 'white',
                marginTop: '.4rem',
                fontWeight: 'bolder',
              }}
            >
              慢病管理从未如此简单
            </div>
            <ul className="chanpin">
              <li>
                <div>适用范围</div>
                <div>希望轻量级开展慢病管理的医院</div>
              </li>
              <li>
                <div>产品载体</div>
                <div>医生Web+App、护士Web、患者App</div>
              </li>
              <li>
                <div>人员要求</div>
                <div>森梅将提供专护团队，不增加院方人员</div>
              </li>
              <li>
                <div>实施部署</div>
                <div>仅需下载医生App，即可开展慢病管理</div>
              </li>
            </ul>
          </div>
          <div style={{ padding: '20px', width: '100%' }}>
            <img src={Summary} style={{ width: '100%' }} />
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <div style={{ zIndex: '10', marginLeft: '.5rem' }}>
            <div style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>控钠助手</div>
            <ul className="chanpin">
              <li>
                <div></div>
                <div style={{ width: '90%' }}>
                  以CBT和ACT理论为基础，结合循证医学的临床治疗方案形成有效的数字疗法产品，从而降低慢性肾脏病患者钠摄入风险。
                  以趣味化的创新产品模式，在保证了医学严谨性、有效性的同时最大限度的提高了用户体验，从而提升用户使用的依从性。
                </div>
              </li>
              <li>
                {renderMore('/number/dtx')}
                <div> </div>
              </li>
            </ul>
          </div>
          <div style={{ padding: '20px', width: '100%' }}>
            <img src={dtx_na} style={{ width: '100%' }} />
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <div style={{ zIndex: '10', marginLeft: '.5rem' }}>
            <div style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>PEW管理</div>
            <ul className="chanpin">
              <li>
                <div></div>
                <div style={{ width: '90%' }}>
                  本软件以《中国慢性肾脏病营养治疗临床实践指南
                  （2021版）》以及《临床营养学》为基础进行营养管理算法设计，可以按照肾脏病患者的实际个体情况，
                  制定不同的个性化营养管理方案，按照不同的管理方案进行营养管理，以改善肾脏病患者的营养状况，软件覆盖多类患者居家饮食场景，提供全方面服务与支撑。
                </div>
              </li>
              <li>
                {renderMore('/number/pew')}
                <div> </div>
              </li>
            </ul>
          </div>
          <div style={{ padding: '20px', width: '100%' }}>
            <img src={pew} style={{ width: '100%' }} />
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }} className="is-show ">
        <div
          style={{
            position: 'absolute',
            top: '-2.5rem',
            width: '.30rem',
            left: '7rem',
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
        {/* <div style={{ position: "absolute", top: "-1rem", width: ".77rem", left: "8rem" }}>
        <img src={circular} style={{ width: "100%" }} />
      </div> */}
        <div
          style={{
            position: 'absolute',
            top: '-.5rem',
            width: '.77rem',
            right: 0,
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
      </div>
      <div className="is-show lunbostyle">
        <div style={{ width: '100%' }} className="topsanjiao">
          <img src={bianzutop} style={{ width: '100%', display: 'block' }} />
        </div>
        <div style={{ width: '100%', position: 'relative' }}>
          <img src={bianzumid} style={{ width: '100%' }} />
          <div className="newSwiper">
            <Swiper
              slidesPerView={1}
              pagination={{ clickable: true }}
              loop={true}
              autoplay={
                (true,
                {
                  delay: 10000,
                  disableOnInteraction: false,
                })
              }
              // onSwiper={(swiper) => {
              //   document.getElementById('befor2').addEventListener('click', function (e) {
              //     e.preventDefault();
              //     swiper.slidePrev();
              //     // swiper.slideNext();
              //   });
              //   document.getElementById('next2').addEventListener('click', function (e) {
              //     e.preventDefault();
              //     // swiper.slideNext();
              //     swiper.slidePrev();
              //   });
              // }}
              speed={2000}
              style={{ width: '100%', height: '100%' }}
            >
              {new Array(4).fill(1).map((e, index) => (
                <SwiperSlide key={index}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      padding: '0.2rem,0 0.2rem 0',
                    }}
                  >
                    <div className={'newleft1  wow textLeft'}>
                      <div
                        style={{
                          fontSize: '.8rem',
                          color: 'white',
                          opacity: '14%',
                        }}
                      >
                        PRODUCT
                      </div>
                      <div
                        style={{
                          fontSize: '.55rem',
                          color: 'white',
                          marginTop: '-1rem',
                          marginLeft: '.7rem',
                          fontWeight: 'bolder',
                        }}
                      >
                        产品全景
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '.6rem',
                          fontSize: '.25rem',
                          height: '.6rem',
                        }}
                      >
                        {['专业版', '轻量版', '控钠助手', 'PEW管理'].map((e, i) => (
                          <>
                            <div
                              style={{
                                color: 'white',
                                marginLeft: '.2rem',
                                fontWeight: i == index && 'bolder',
                                opacity: i != index && '44%',
                              }}
                            >
                              {e}
                            </div>
                            {i < 3 && (
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  marginLeft: '.2rem',
                                  height: '.2rem',
                                  width: '.02rem',
                                  borderRadius: '.1rem',
                                  marginTop: '0.08rem',
                                  opacity: '44%',
                                }}
                              ></div>
                            )}
                          </>
                        ))}
                      </div>
                      {index == 0 && (
                        <>
                          <div
                            style={{
                              color: 'white',
                              marginTop: '.2rem',
                              fontWeight: 'normal',
                            }}
                          >
                            打造全国标杆级慢病管理的全场景临床抓手工具
                          </div>
                          <ul className="chanpin">
                            <li>
                              <div>适用范围</div>
                              <div>自建团队开展慢病管理同座的三甲医院</div>
                            </li>
                            <li>
                              <div>产品载体</div>
                              <div>内网系统+患者App</div>
                            </li>
                            <li>
                              <div>人员要求</div>
                              <div>需要设置专科护士</div>
                            </li>
                            <li>
                              <div>实施部署</div>
                              <div>需要院内系统接口，实施周期一月左右</div>
                            </li>
                          </ul>
                        </>
                      )}
                      {index == 1 && (
                        <>
                          <div
                            style={{
                              color: 'white',
                              marginTop: '.4rem',
                            }}
                          >
                            慢病管理从未如此简单
                          </div>
                          <ul className="chanpin">
                            <li>
                              <div>适用范围</div>
                              <div>希望轻量级开展慢病管理的医院</div>
                            </li>
                            <li>
                              <div>产品载体</div>
                              <div>医生Web+App、护士Web、患者App</div>
                            </li>
                            <li>
                              <div>人员要求</div>
                              <div>森梅将提供专护团队，不增加院方人员</div>
                            </li>
                            <li>
                              <div>实施部署</div>
                              <div>仅需下载医生App，即可开展慢病管理</div>
                            </li>
                          </ul>
                        </>
                      )}
                      {index == 2 && (
                        <>
                          <ul className="chanpin">
                            <li className="li-style-none">
                              <div style={{ width: '90%', fontSize: '.2rem' }}>
                                以CBT和ACT理论为基础，结合循证医学的临床治疗方案形成有效的数字疗法产品，从而降低慢性肾脏病患者钠摄入风险。
                                以趣味化的创新产品模式，在保证了医学严谨性、有效性的同时最大限度的提高了用户体验，从而提升用户使用的依从性。
                              </div>
                            </li>
                            <li className="li-style-none">
                              {renderMore('/number/dtx')}
                              <div> </div>
                            </li>
                          </ul>
                        </>
                      )}
                      {index == 3 && (
                        <>
                          <ul className="chanpin">
                            <li className="li-style-none">
                              <div style={{ width: '90%', fontSize: '.2rem' }}>
                                本软件以《中国慢性肾脏病营养治疗临床实践指南
                                （2021版）》以及《临床营养学》为基础进行营养管理算法设计，可以按照肾脏病患者的实际个体情况，
                                制定不同的个性化营养管理方案，按照不同的管理方案进行营养管理，以改善肾脏病患者的营养状况，软件覆盖多类患者居家饮食场景，提供全方面服务与支撑。
                              </div>
                            </li>
                            <li className="li-style-none">
                              {renderMore('/number/pew')}
                              <div> </div>
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                    <div>
                      <img src={[phoneAcom, Summary, dtx_na, pew][index]} style={{ width: '8rem', display: 'block' }} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div style={{ width: '100%' }} className="swiper-bottom">
          <img src={bianzubuttom} style={{ width: '100%', display: 'block' }} />
        </div>
      </div>
      <div style={{ position: 'relative' }} className="is-show ">
        <div
          style={{
            position: 'absolute',
            top: '-.5rem',
            width: '.55rem',
            left: '.5rem',
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
      </div>
      <div className={'total-liucheng'}>
        <div className="liucheng">
          <div style={{ margin: '0 0 0 .5rem' }}>
            <img src={toubu} style={{ display: 'block' }} className="sanjia" />
            <div
              style={{
                textAlign: 'center',
                marginTop: '1rem',
                color: '#9B9EA7',
                fontSize: '.28rem',
                fontWeight: 'bolder',
              }}
            >
              头部三甲医院适用
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <div className="pingju firstbox textLeft">
              <div
                style={{
                  fontSize: '.6rem',
                  fontWeight: 'bolder',
                  whiteSpace: 'nowrap',
                }}
              >
                提供医联体级慢病管理
              </div>
              <div
                style={{
                  fontSize: '.3rem',
                  color: '#9B9EA7',
                  fontWeight: '400',
                  marginTop: '.2rem',
                }}
              >
                整体解决方案
              </div>
            </div>
            <div className="pingju secondbox">
              <img
                src={righyarrow}
                style={{
                  height: '.28rem',
                  display: 'block',
                  alignSelf: 'center',
                }}
              />
              <img
                src={bianzu14}
                style={{
                  height: '1.1rem',
                  display: 'block',
                  alignSelf: 'center',
                }}
              />
              <img
                src={jiahao}
                style={{
                  height: '.52rem',
                  display: 'block',
                  alignSelf: 'center',
                }}
              />
              <img
                src={bianzu15}
                style={{
                  height: '1.1rem',
                  display: 'block',
                  alignSelf: 'center',
                }}
              />
            </div>
            <div className="pingju secondbox">
              <div style={{ width: '0.1rem', color: '#35B5D3', fontSize: '.2rem' }}>知识数据化</div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="secondbox">
              <img
                src={righyarrow}
                style={{
                  height: '.28rem',
                  display: 'block',
                  alignSelf: 'center',
                }}
              />
              <div style={{ width: '1.1rem' }}></div>
              <div style={{ width: '1.1rem' }}></div>
              <div
                style={{
                  width: '2.72rem',
                  color: '#9B9EA7',
                  fontSize: '.28rem',
                  marginLeft: '-1.7rem',
                  fontWeight: 'bolder',
                }}
              >
                下级联盟医院适用
              </div>
            </div>
          </div>
        </div>
        {/* <div className={"animate-bounce-down1 is-show"} onClick={() => {
        var goTop = setInterval(scrollMove, 1);
        function scrollMove() {
          // if (document.documentElement.scrollTop > document.getElementsByClassName("zixunback")[0].offsetHeight + document.getElementsByClassName("total-gsjj")[0].offsetHeight - 100) {
          //   return
          // }
          document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
          if (document.documentElement.scrollTop > document.getElementsByClassName("zixunback")[0].offsetHeight + document.getElementsByClassName("total-gsjj")[0].offsetHeight + document.getElementsByClassName("lunbostyle")[0].offsetHeight + document.getElementsByClassName("total-liucheng")[0].offsetHeight + document.getElementsByClassName("swiper-bottom")[0].offsetHeight - 50) clearInterval(goTop);
        }
      }}>
        <div><img src={themebottom2} style={{ width: ".30rem", display: "block", margin: "0 auto" }} /></div>
        <div><img src={themebottom1} style={{ width: ".30rem", marginTop: "-20px", display: "block", margin: "0 auto" }} /></div>
        <div><img src={themebottom3} style={{ width: ".30rem", marginTop: "-40px", display: "block", margin: "0 auto" }} /></div>
      </div> */}
      </div>
      <div className="smallliucheng">
        <img src={smallliucheng} />
      </div>
      <div style={{ position: 'relative' }} className="is-show ">
        <div
          style={{
            position: 'absolute',
            top: '-.5rem',
            width: '.88rem',
            left: '.2rem',
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
        <div
          style={{
            position: 'absolute',
            top: '1rem',
            width: '.44rem',
            left: '8rem',
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
      </div>

      <div className="rightclass">
        <img src={lujing23} style={{ float: 'right', height: '8.67rem' }} />
      </div>

      <div className={'mapstyle'}>
        <div className="maptitle textLeft">
          <p>重点合作医院</p>
          <p>全国布局，Professional+Light，大带小拓展</p>
        </div>
        <div style={{ position: 'relative' }} className="mapdetails textLeft">
          <img src={mapofchina} className="map" />
          <div className={'firstdingwei updown'}>
            <div style={{ position: 'relative' }}>
              <img src={dingwei} className="dingweistyle" />
              <div className="dingweitext">
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 3 });
                  }}
                >
                  新疆自治区人民医院
                </p>
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 10 });
                  }}
                >
                  兰州大学第二医院
                </p>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className={'seconddingwei updown'}>
            <div style={{ position: 'relative' }}>
              <img src={dingwei} className="dingweistyle" />
              <div className="dingweitext ">
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: null });
                  }}
                >
                  大连医科大学第一医院
                </p>
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: null });
                  }}
                >
                  吉林省人民医院
                </p>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className={'thirddingwei updown'}>
            <div style={{ position: 'relative' }}>
              <img src={dingwei} className="dingweistyle" />
              <div className="dingweitext">
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 1 });
                  }}
                >
                  解放军总医院301
                </p>
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: null });
                  }}
                >
                  山西医科大学第二医院
                </p>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className={'fourthdingwei updown'}>
            <div style={{ position: 'relative' }}>
              <img src={dingwei} className="dingweistyle" />
              <div className="dingweitext">
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: null });
                  }}
                >
                  第三军医大学新桥医院
                </p>
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 4 });
                  }}
                >
                  四川大学华西医院
                </p>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className={'fifthdingwei updown'}>
            <div style={{ position: 'relative' }}>
              <img src={dingwei} className="dingweistyle" />
              <div className="dingweitext">
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 7 });
                  }}
                >
                  广西医科大学附属第一医院
                </p>
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: null });
                  }}
                >
                  北京大学深圳医院
                </p>
              </div>
            </div>
          </div>
          <div className={'sixthdingwei updown'}>
            <div style={{ position: 'relative' }}>
              <img src={dingwei} className="dingweistyle" />
              <div className="dingweitext">
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 6 });
                  }}
                >
                  中南大学湘雅医院
                </p>
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 9 });
                  }}
                >
                  河南省人民医院
                </p>
                <p>...</p>
              </div>
            </div>
          </div>
          <div className={'seventhdingwei updown'}>
            <div style={{ position: 'relative' }}>
              <img src={dingwei} className="dingweistyle" />
              <div className="dingweitext">
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 8 });
                  }}
                >
                  上海交通大学仁济医院
                </p>
                <p
                  onClick={() => {
                    props.history.push({ pathname: '/cases', query: 2 });
                  }}
                >
                  东南大学中大医院
                </p>
                <p>...</p>
              </div>
            </div>
            {/* <div className={"animate-bounce-down1 is-show"} style={{ marginLeft: "2rem", marginTop: "1.5rem" }} onClick={() => {
            var goTop = setInterval(scrollMove, 1);
            function scrollMove() {
              // if (document.documentElement.scrollTop > document.getElementsByClassName("zixunback")[0].offsetHeight + document.getElementsByClassName("total-gsjj")[0].offsetHeight - 100) {
              //   return
              // }
              document.documentElement.scrollTop = document.documentElement.scrollTop + 10;
              if (document.documentElement.scrollTop = document.documentElement.scrollHeight + 100) clearInterval(goTop);
            }
          }}>
            <div><img src={themebottom2} style={{ width: ".30rem", display: "block", margin: "0 auto" }} /></div>
            <div><img src={themebottom1} style={{ width: ".30rem", marginTop: "-20px", display: "block", margin: "0 auto" }} /></div>
            <div><img src={themebottom3} style={{ width: ".30rem", marginTop: "-40px", display: "block", margin: "0 auto" }} /></div>
          </div> */}
          </div>
        </div>
      </div>

      <div className="smallmap">
        <img src={smallmap} />
      </div>
      <div style={{ position: 'relative' }} className="is-show ">
        <div
          style={{
            position: 'absolute',
            top: '-3.5rem',
            width: '.88rem',
            left: '.2rem',
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
        <div
          style={{
            position: 'absolute',
            top: '1rem',
            width: '.44rem',
            left: '8rem',
          }}
        >
          <img src={circular} style={{ width: '100%' }} />
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        {/* <div style={{ position: "absolute", top: "3.5rem", textAlign: "center", width: "100%", color: "white" }}>
        <div style={{ position: "relative" }}>
          <div style={{ fontSize: ".8rem", opacity: "12%" }}>DYNAMIC</div>
          <div style={{ fontSize: ".6rem", position: "absolute", top: ".1rem", left: "8.6rem" }}>企业动态</div>
        </div>
      </div> */}
        <div className={'totalbottom'}>
          <div className={'totalbottom-top'}>
            <div>
              <img src={bottomtops} style={{ width: '100%' }} />
            </div>
            <div className="totalbottom-top-text1">DYNAMIC</div>
            <div className="totalbottom-top-text2">企业动态</div>
          </div>
          <div className="bottomdown">
            <div style={{ overflow: 'auto' }} className="bottompadding">
              <div
                className="bottom-box"
                onClick={() => {
                  props.history.push('/details1');
                }}
              >
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  <img src={bottom1} style={{ width: '100%', display: 'block' }} />
                </div>
                <div className={'bottomtext'}>
                  <div className="bottomtext-top">森梅医疗获医联数千万天使轮融资，慢病临床管理工具快速落地</div>
                  <div className="bottomtext-bottom">2020-04-09</div>
                </div>
              </div>
              <div
                className="bottom-box"
                onClick={() => {
                  props.history.push('/details2');
                }}
              >
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  <img src={bottom2} style={{ width: '100%', display: 'block' }} />
                </div>
                <div className={'bottomtext'}>
                  <div className="bottomtext-top">慢病管理需求增强，「森梅医疗」 想用AI助力患者健康</div>
                  <div className="bottomtext-bottom">2019-10-22</div>
                </div>
              </div>
              <div
                className="bottom-box is-show"
                onClick={() => {
                  props.history.push('/details3');
                }}
              >
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  <img src={bottom3} style={{ width: '100%', display: 'block' }} />
                </div>
                <div className={'bottomtext'}>
                  <div className="bottomtext-top">平均两周落地一家三甲医院，森梅医疗这样做临床病情管理平台</div>
                  <div className="bottomtext-bottom">2019-10-22</div>
                </div>
              </div>
              <div
                className="bottom-box is-show"
                onClick={() => {
                  props.history.push('/details4');
                }}
              >
                <div style={{ width: '100%', overflow: 'hidden' }}>
                  <img src={bottom4} style={{ width: '100%', display: 'block' }} />
                </div>
                <div className={'bottomtext'}>
                  <div className="bottomtext-top">数字疗法产业中国行(重庆站）：探索数字疗法的“中国实践”</div>
                  <div className="bottomtext-bottom">2021-06-01</div>
                </div>
              </div>
            </div>
            <div
              className="is-show"
              style={{
                textAlign: 'center',
                padding: '.9rem 0',
                fontSize: '.3rem',
                color: 'white',
              }}
            >
              <img
                src={checkmore}
                style={{ cursor: 'pointer', height: '20px' }}
                onClick={() => {
                  props.history.push('/news');
                }}
              />
            </div>
            <Footer showback={true} />
          </div>
        </div>

        {/* <div style={{ position: "absolute", width: "100%", top: "5rem" }}>
        <div className="row">
          <div className={'col-xs-6 col-sm-3'}>
            <div>
              <img src={bottom1} className={"bottomimg"} />
            </div>
            <div className="bottomtext" >
              <div style={{ fontSize: ".2rem" }}>
                第四届中国医疗产业创新与发展大会圆满闭幕  明年4月再会
              </div>
              <div style={{ textAlign: "end" }}>
                2021-03-23
              </div>
            </div>
          </div>
          <div className={' col-xs-6 col-sm-3'}>
            <img src={bottom2} className={"bottomimg"} />
            <div className="bottomtext">
              <div style={{ fontSize: ".2rem" }}>
                第四届中国医疗产业创新与发展大会圆满闭幕  明年4月再会
              </div>
              <div style={{ textAlign: "end" }}>
                2021-03-23
              </div>
            </div>
          </div>
          <div className={'col-xs-6 col-sm-3'}>
            <img src={bottom3} className={"bottomimg"} />
            <div className="bottomtext">
              <div style={{ fontSize: ".2rem" }}>
                第四届中国医疗产业创新与发展大会圆满闭幕  明年4月再会
              </div>
              <div style={{ textAlign: "end" }}>
                2021-03-23
              </div>
            </div>
          </div>
          <div className={'col-xs-6 col-sm-3'}>
            <img src={bottom3} className={"bottomimg"} />
            <div className="bottomtext">
              <div style={{ fontSize: ".2rem" }}>
                第四届中国医疗产业创新与发展大会圆满闭幕  明年4月再会
              </div>
              <div style={{ textAlign: "end" }}>
                2021-03-23
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};
export default Home;
